import { useState, useEffect } from 'react';

const InfoCard = (props: any) => {
    const container: any = {
        height: '100px',
        width: '200px',
        backgroundColor: 'white',
        borderRadius: 12,
        marginRight: '20px',
        marginBottom: '20px',
        padding: '10px',
        boxShadow: 'rgb(226, 235, 238) 3px 3px 8px 3px',
    };

    const containerAzure: any = {
        height: '100px',
        width: '200px',
        backgroundColor: '#f5fbff',
        borderRadius: 12,
        marginRight: '20px',
        marginBottom: '20px',
        padding: '10px',
        boxShadow: 'rgb(206 206 206) 3px 3px 5px 0px',
    };

    const name: any = {
        fontSize: '15px',
        margin: 0,
        textAlign: 'center',
    };

    const nameAzure: any = {
        fontSize: '15px',
        margin: 0,
        fontWeight: 600,
        textAlign: 'center',
    };

    const number: any = {
        fontWeight: 700,
        fontSize: '18px',
        color: '#005591',
        marginTop: '7px',
        textAlign: 'center',
    };

    const numberAzure: any = {
        fontWeight: 900,
        fontSize: '18px',
        color: '#005591',
        marginTop: '7px',
        textAlign: 'center',
    };

    return (
        <div style={props.name === 'Number of Azure B2C Users' ? containerAzure : container}>
            <p style={props.name === 'Number of Azure B2C Users' ? nameAzure : name}>
                {props.name}
            </p>
            <p style={props.name === 'Number of Azure B2C Users' ? numberAzure : number}>
                {props.number}
            </p>
        </div>
    );
};

export default InfoCard;
