import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { IBusinessRelationship } from '../../../types';

interface BusinessRelationshipOption {
    id: IBusinessRelationship['internalId'];
    label: IBusinessRelationship['name'];
    externalId: IBusinessRelationship['externalId'];
}

function mapResponseToOptions(response: IBusinessRelationship[]): BusinessRelationshipOption[] {
    return response.map(({ internalId: id, name: label, externalId }) => ({
        externalId,
        id,
        label,
    }));
}

interface UseBusinessRelationshipState {
    businessRelationships: BusinessRelationshipOption[];
    isBusinessRelationshipLoading: boolean;
    getBusinessRelationships: () => void;
}

export const useBusinessRelationships = (): UseBusinessRelationshipState => {
    const {
        state: { user, chosenBusinessSegment },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: businessRelationships = [],
        trigger: getBusinessRelationships,
        isLoading: isBusinessRelationshipLoading,
    } = useAsyncTrigger(async () => {
        if (!user || !chosenBusinessSegment) {
            throw new Error('User and business segment must be defined');
        }

        const businessRelationshipResponse = await api.adHocInvite.getBusinessRelationships(
            user,
            chosenBusinessSegment.internalId,
            generateToken(instance, accounts),
        );

        return mapResponseToOptions(businessRelationshipResponse);
    });
    return { businessRelationships, isBusinessRelationshipLoading, getBusinessRelationships };
};
