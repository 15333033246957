import { useContext } from 'react';
import { UserContext } from '../../context';
import AdHocElevate from './AdHocElevate';
import AdHocLidap from './AdHocLidap';

const AdHocInvites = () => {
    const { state } = useContext(UserContext);

    return (
        <>
            {state.appId === 3 ? (
                <AdHocLidap />
            ) : state.appId === 1 || state.appId === 8 ? (
                <AdHocElevate />
            ) : null}
        </>
    );
};

export default AdHocInvites;
