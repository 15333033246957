import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { IBusinessRelationship, ILocation, User } from '../../../types';
import { CountryCode } from '../../../types/countryCode';

interface CountryCodeOption {
    id: CountryCode['alpha3'];
    label: `${CountryCode['alpha3']} - ${CountryCode['name']}`;
}

function mapResponseToOptions(response: ILocation[]): CountryCodeOption[] {
    return response.map(
        (loc): CountryCodeOption => ({
            id: loc.alpha3,
            label: `${loc.alpha3} - ${loc.name}`,
        }),
    );
}

interface UseCountryCodeState {
    countryCodes: CountryCodeOption[];
    isLoadingCountryCodes: boolean;
    getCountryCodes: () => void;
}

export const useCountryCode = (): UseCountryCodeState => {
    const {
        state: { user },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: countryCodes = [],
        isLoading: isLoadingCountryCodes,
        trigger: getCountryCodes,
    } = useAsyncTrigger(async () => {
        if (!user) {
            throw new Error('No user found in context');
        }

        const response = await api.adHocInvite.getCountryCodes(
            user,
            generateToken(instance, accounts),
        );

        return mapResponseToOptions(response);
    });

    return { countryCodes, isLoadingCountryCodes, getCountryCodes };
};
