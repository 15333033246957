import axios from 'axios';
import { User } from '../../types';
import { toast } from 'react-toastify';
import i18n from 'i18next';

export const linxApi = (fetchApi: any) => {
    async function getRegionsAndCountries(loggedUser: User, token: any, data: any) {
        return axios
            .post(`api/LinxRegion/GetLinxRegionsAndBusinesses`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res.data;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function getBusinessEvents(loggedUser: User, token: any, data: any, countryID: number) {
        return axios
            .post(`api/LinxBusinessEvent/GetLinxBusinessEvents/${countryID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res.data;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    function getLinxCompanies(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxCompany/GetLinxCompaniesByBusinessId/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxPlants(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxPlant/GetPlantsPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxContacts(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxContact/GetActiceContactsPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getInactiveContacts(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxContact/GetNonActiveContactsPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getAvailableLinxContacts(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxContact/GetAvailableContactsPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getSupportedLanguagesLinx(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxBusinessConfiguration/GetSupportedLanguages/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxContactsForPlants(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxContact/GetContactsForPlant/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxPosRegions(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxBusinessConfiguration/GetPosPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxAgentGroup(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxBusinessConfiguration/GetGroupsPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxTimeZones(loggedUser: User, countryID: number, token: any) {
        return fetchApi(
            `api/LinxBusinessConfiguration/GetTimeZonesPerBusiness/${countryID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLinxContctsForPlant(loggedUser: User, plantID: number, token: any) {
        return fetchApi(
            `api/LinxContact/GetContactsForPlant/${plantID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    async function getLinxRoles(loggedUser: User, countryID: number, data: any, token: any) {
        return axios
            .post(`api/Configuration/GetRole/${countryID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function getPlantNo(loggedUser: User, contactID: number, token: any) {
        return fetchApi(
            `api/LinxContact/GetPlantNo/${contactID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    async function addLinxCompany(loggedUser: User, countryID: number, data: any, token: any) {
        return axios
            .post(`api/LinxCompany/CreateLinxCompany/${countryID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                toast.success('OK');
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function updateCompany(loggedUser: User, countryID: number, data: any, token: any) {
        return axios
            .post(`api/LinxCompany/UpdateLinxCompany/${countryID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function addLinxContact(loggedUser: User, data: any, token: any) {
        return axios
            .post(`api/LinxContact/CreateContact`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                toast.success('OK');
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function addLinxPlant(loggedUser: User, countryID: number, data: any, token: any) {
        return axios
            .post(`api/LinxPlant/CreatePlant/${countryID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                toast.success('OK');
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
                return err;
            });
    }

    async function addLinxPlantToContact(loggedUser: User, plantID: number, data: any, token: any) {
        return axios
            .patch(`api/LinxContact/AddPlantToContacts?plantId=${plantID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                toast.success(res.data);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function reassignCompany(
        loggedUser: User,
        plantID: number,
        companyID: number,
        token: any,
    ) {
        return axios
            .patch(` api/LinxPlant/ReassignParentCompany/${plantID}?companyId=${companyID}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                toast.success('OK');
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function createHPSMRequest(loggedUser: User, countryID: number, data: any, token: any) {
        return axios
            .post(`api/HPSMRequest/CreateHPSMRequest/${countryID}`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                toast.success(res.data);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function deassignContact(loggedUser: User, contactID: number, token: any) {
        return axios
            .post(`api/LinxContact/DeassignContact/${contactID}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                const message = i18n.t('label.deassigned');
                toast.success(message);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function assignContact(loggedUser: User, contactID: number, plantID: number, token: any) {
        return axios
            .post(`api/LinxContact/AssignContact/${contactID}/${plantID}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                const message = i18n.t('label.assigned');
                toast.success(message);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function lockPlant(loggedUser: User, plantID: number, token: any) {
        return axios
            .post(`api/LinxPlant/LockPlant/${plantID}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                const message = i18n.t('label.locked');
                toast.success(message);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function unlockPlant(loggedUser: User, plantID: number, token: any) {
        return axios
            .post(`api/LinxPlant/UnlockPlant/${plantID}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                const message = i18n.t('label.unlocked');
                toast.success(message);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function deletePlant(
        loggedUser: User,
        plantID: number,
        comment: string,
        date: any,
        token: any,
    ) {
        return axios
            .post(`api/LinxPlant/DeletePlant/${plantID}?comment=${comment}&date=${date}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                    SAMAccountName: loggedUser.SAMAccountName,
                },
            })
            .then((res: any) => {
                toast.success('Plant deleted');
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function inviteContact(
        loggedUser: User,
        contactID: number,
        appInfoId: number,
        token: any,
    ) {
        return axios
            .post(`api/EntitlementRequest/SendLinxInvite/${contactID}/${appInfoId}`, null, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                const message = i18n.t('label.InviteSuccessful');
                toast.success(message);
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function approveLinxEntitlementRequest(loggedUser: User, data: any, token: any) {
        return axios
            .post(`api/EntitlementRequest/ApproveLinxEntitlementRequest`, data, {
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    function getPosRegionPerBusiness(loggedUser: User, businessID: number, token: any) {
        return fetchApi(
            `api/LinxBusinessConfiguration/GetPosRegionPerBusiness/${businessID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getDistChannelField(loggedUser: User, businessID: number, token: any) {
        return fetchApi(
            `api/LinxBusinessConfiguration/GetDistChannelPerBusiness/${businessID}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getDateTimeInUserTimezone(dateTimeString: any, userTimeZone: any) {
        // Check if the input string contains milliseconds
        const hasMilliseconds = dateTimeString.includes('.');

        // Split the datetime string into date and time components
        const dateTimeParts = dateTimeString.split('T');
        const datePart = dateTimeParts[0];
        const timePart = dateTimeParts[1].split('.')[0];
        const milliseconds = hasMilliseconds ? dateTimeParts[1].split('.')[1] : '000';

        // Create a new Date object using the date and time components
        const date = new Date(`${datePart}T${timePart}.${milliseconds}Z`);

        // Get the user's timezone (or use computer's local timezone if not provided)
        userTimeZone = userTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

        const options: any = {
            timeZone: userTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: hasMilliseconds ? '2-digit' : undefined,
            hour12: false,
        };

        if (hasMilliseconds) {
            options.millisecond = '3-digit';
        }

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDateTime = formatter.format(date);

        return formattedDateTime;
    }

    return {
        getRegionsAndCountries,
        getLinxCompanies,
        addLinxCompany,
        getLinxContacts,
        getLinxContactsForPlants,
        addLinxContact,
        getLinxPlants,
        addLinxPlant,
        addLinxPlantToContact,
        createHPSMRequest,
        getLinxPosRegions,
        getAvailableLinxContacts,
        getBusinessEvents,
        getLinxRoles,
        getLinxContctsForPlant,
        deassignContact,
        assignContact,
        lockPlant,
        unlockPlant,
        approveLinxEntitlementRequest,
        deletePlant,
        inviteContact,
        getLinxAgentGroup,
        getDateTimeInUserTimezone,
        getSupportedLanguagesLinx,
        updateCompany,
        reassignCompany,
        getLinxTimeZones,
        getPlantNo,
        getInactiveContacts,
        getPosRegionPerBusiness,
        getDistChannelField,
    };
};
