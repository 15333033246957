import { useContext, useEffect } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { useMsal } from '@azure/msal-react';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { api } from '../../../services';
import { User } from '../../../types';

interface UseLayoutState {
    isLastAppLoading: boolean;
    lastAppId: number;
    setLastAppId: (appId: number) => void;
}
export const useUserLastApp = (): UseLayoutState => {
    const {
        state: { user, appId },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    //fallback user to empty object
    const { firstName, lastName, role } = user || {};

    const {
        isLoading: isLastAppLoading,
        trigger: getLastAppId,
        result: lastAppId,
    } = useAsyncTrigger(async (_, appId?: number) => {
        if (!user) {
            throw new Error('User must be defined');
        }

        // so that trigger just returns appId that we pass, kind like setAppId
        if (appId) {
            return appId;
        }

        const lastApp: any = await api.userDetails.getUsersLastApp(
            user,
            generateToken(instance, accounts),
        );

        if (lastApp) {
            return lastApp.id;
        } else {
            throw new Error('Failed to get lastAppId');
        }
    });

    useEffect(() => {
        if (firstName && lastName) {
            getLastAppId();
        }
        //we need to add role in dep because it updates later on for some reason TODO: investigate why is that?
    }, [firstName, getLastAppId, lastName, role]);

    return { isLastAppLoading, lastAppId, setLastAppId: getLastAppId };
};
