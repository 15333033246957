import { useContext, useEffect } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { LanguageOption } from '../../../types/languageOption';

interface UseSupportedLanguagesState {
    languageOptions: LanguageOption[];
    isLoadingLanguageOptions: boolean;
    getLanguageOptions: () => void;
}

export const useSupportedLanguages = (): UseSupportedLanguagesState => {
    const {
        state: { appId },
    } = useContext(UserContext);

    const {
        result: languageOptions = [],
        trigger: getLanguageOptions,
        isLoading: isLoadingLanguageOptions,
    } = useAsyncTrigger(async () => {
        if (!appId) {
            throw new Error('appId must be defined');
        }

        return await api.userDetails.getSupportedLanguages(appId);
    });

    useEffect(() => {
        if (appId) {
            getLanguageOptions();
        }
    }, [appId, getLanguageOptions]);

    return { languageOptions, isLoadingLanguageOptions, getLanguageOptions };
};
