import { useMsal } from '@azure/msal-react';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { useState, useEffect, useContext, useTransition } from 'react';
import InfoCard from '../../components/dashboard/InfoCard';
import { UserContext } from '../../context';
import { api } from '../../services';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
    const { instance, accounts } = useMsal();
    const { state, dispatch } = useContext(UserContext);
    const [azureData, setAzureData] = useState<any>([]);
    const [appsData, setAppsData] = useState<any>([]);
    const [infoLoading, setInfoLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const container: any = {
        display: 'flex',
        flexWrap: 'wrap',
    };

    useEffect(() => {
        setInfoLoading(true);
        state.user.role === 'SuperAdministrator' &&
            api.userDetails
                .getInfoForDashboard(state.user, generateToken(instance, accounts))
                .then((res: any) => {
                    setInfoLoading(false);
                    setAzureData(res.azure);
                    setAppsData(res.applications);
                });
    }, []);

    return (
        <>
            {infoLoading ? (
                <LinearProgress />
            ) : (
                <div style={container}>
                    <InfoCard name={'Number of Azure B2C Users'} number={azureData.number} />
                    {appsData.map((data: any) => {
                        return (
                            <InfoCard
                                name={t('label.NumberOfEntitlements') + ' ' + data.title}
                                number={data.number}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default Dashboard;
