import { useContext, useEffect } from 'react';
import { UserContext } from '../../../context';
import { usePrevious } from '../../../hooks/usePrevious';
import { AppIdEnum } from '../../../types/app';
import { equal } from '../../../util/object';
import { useBusinessRelationships } from './useBusinessRelationships';
import { useCornerstoneLabels } from './useCornerstoneLabels';
import { useCountryCode } from './useCountryCode';
import { useExternalPositions } from './useExternalPosition';
import { useMandatoryFields } from './useMandatoryFields';
import { useOrgUnits } from './useOrgUnits';
import { useSupportInfo } from './useSupportInfo';
import { useUserSupportInfo } from './useUserSupportInfo';

interface UseChosenBusinessSegmentProps {
    type: 'online' | 'offline';
}

export const useChosenBusinessSegment = ({ type }: UseChosenBusinessSegmentProps) => {
    const {
        state: { chosenBusinessSegment, user, appId },
    } = useContext(UserContext);
    const { getCountryCodes, countryCodes, isLoadingCountryCodes } = useCountryCode();
    const { businessRelationships, getBusinessRelationships, isBusinessRelationshipLoading } =
        useBusinessRelationships();
    const { orgUnits, isOrgUnitsLoading, getOrgUnits } = useOrgUnits();
    const { externalPositions, getExternalPositions, isExternalPositionsLoading } =
        useExternalPositions();
    const { cornerstoneLabels, getCornerstoneLabels, isCSDataLoading } = useCornerstoneLabels();
    const { userSupportInfo, getUserSupportInfo, isUserSupportInfoLoading } = useUserSupportInfo();
    const { supportInfo, getSupportInfo, isSupportInfoLoading } = useSupportInfo();
    const { mandatoryFields, getMandatoryFields, isMandatoryLoading } = useMandatoryFields();
    const prevBusinessSegment = usePrevious(chosenBusinessSegment);

    // can refactor to use Promise.all() and ensure that all requests are sucessfully fetched.
    useEffect(() => {
        if (
            user &&
            chosenBusinessSegment &&
            appId &&
            // this is ensuring that we don't enter this again if choseBusinessSegment hasn't changed
            !equal(prevBusinessSegment, chosenBusinessSegment)
        ) {
            getCountryCodes();

            // LIDAP doesn't have businessSegment so we do this only for other apps
            if (appId !== AppIdEnum.LIDAP) {
                getBusinessRelationships();
                getOrgUnits();
                getExternalPositions();
                getCornerstoneLabels();
                getUserSupportInfo();
                getSupportInfo();
                getMandatoryFields(type === 'online');
            }
        }
    }, [
        chosenBusinessSegment,
        user,
        appId,
        getCountryCodes,
        getBusinessRelationships,
        getOrgUnits,
        getExternalPositions,
        getCornerstoneLabels,
        getUserSupportInfo,
        getSupportInfo,
        getMandatoryFields,
        prevBusinessSegment,
        type,
    ]);

    // we should probably in the future use this isLoading, and do nothing until everything is fetched sucessfully
    const isLoading =
        isLoadingCountryCodes ||
        isBusinessRelationshipLoading ||
        isOrgUnitsLoading ||
        isExternalPositionsLoading ||
        isCSDataLoading ||
        isUserSupportInfoLoading ||
        isSupportInfoLoading ||
        isMandatoryLoading;

    // can be refactor by removing all isLoading and leave just one that collects all of them.
    return {
        isLoading,
        countryCodes,
        businessRelationships,
        orgUnits,
        externalPositions,
        cornerstoneLabels,
        userSupportInfo,
        supportInfo,
        mandatoryFields,
    };
};
