import React, { useState, useContext } from 'react';
import { UserContext } from '../../context';
import TreeNode from './TreeNode';
import TreeSearch from './TreeSearch';

export default function TreeView({ children, selectedItem, setSelectedItem }: any) {
    const { state, dispatch } = useContext(UserContext);
    /*    const [selectedItem, setSelectedItem] = useState<any>();*/
    const [searchQuery, setSearchQuery] = useState('');

    function handleSelectItem(item: any) {
        setSelectedItem(item);
        dispatch({ type: 'SET_SELECTED_ORGUNIT', data: item });
    }

    function handleSearchChange(event: any) {
        setSearchQuery(event.target.value);
    }

    return (
        <div>
            <ul style={{ paddingLeft: 0, marginTop: 0 }}>
                <TreeNode
                    node={children}
                    onSelect={handleSelectItem}
                    searchQuery={searchQuery}
                    selectedItem={selectedItem}
                />
            </ul>
        </div>
    );
}
