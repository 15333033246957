import { styled } from '@mui/material';
import elevateImg from '../assets/elevate.png';
import lidapImg from '../assets/lidap.png';
import { DataGrid } from '@mui/x-data-grid';

export const InputContainer = styled('div')(() => ({
    marginBottom: '10px',
    position: 'relative',
}));

export const CornerStoneData = styled('fieldset')(() => ({
    width: '100%',
    margin: 'auto',
    marginLeft: '48px',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '45px',
    paddingRight: '45px',
    borderWidth: '0px',
    backgroundColor: '#F6FCFF',
}));

export const Error = styled('span')(() => ({
    color: 'red',
    fontSize: '10px',
    margin: 0,
    display: 'none',
    marginLeft: '5px',
    marginBottom: '2px',
}));

export const NoDataCircle = styled('div')(() => ({
    backgroundColor: '#F9FBFC',
    borderRadius: '50%',
    width: '30vh',
    height: '30vh',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10vh',
    fontSize: '5vh',
}));

export const ElevateCard = styled('div')(({ theme }) => ({
    width: '200px',
    height: '100px',
    backgroundImage: `url(${elevateImg})`,
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    padding: '20px',
    marginLeft: '20px',
    marginBottom: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '&:hover': {
        opacity: '90%',
    },
    position: 'relative',
    zIndex: '0',
}));

export const ElevateCardDisabled = styled('div')(({ theme }) => ({
    width: '200px',
    height: '100px',
    backgroundImage: `url(${elevateImg})`,
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    padding: '20px',
    marginLeft: '20px',
    marginBottom: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    zIndex: '0',
    opacity: 0.5,
}));

export const LidapCard = styled('div')(({ theme }) => ({
    width: '200px',
    height: '100px',
    backgroundColor: 'white',
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    padding: '20px',
    marginLeft: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundImage: `url(${lidapImg})`,
    backgroundPosition: '25% 75%',
    backgroundSize: 'cover',
    '&:hover': {
        opacity: '90%',
    },
    position: 'relative',
}));

export const LidapCardDisabled = styled('div')(({ theme }) => ({
    width: '200px',
    height: '100px',
    backgroundColor: 'white',
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    padding: '20px',
    marginLeft: '20px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundImage: `url(${lidapImg})`,
    backgroundPosition: '25% 75%',
    backgroundSize: 'cover',
    position: 'relative',
    opacity: 0.5,
}));

export const OptionsButton = styled('div')(({ theme }) => ({
    width: '20px',
    height: '20px',
    boxShadow: '5px 5px 12px -6px rgba(0, 0, 0, 1)',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: `white`,
    position: 'absolute',
    right: '-8px',
    top: '-8px',
    zIndex: '7',
}));

export const ManagersData = styled('fieldset')(() => ({
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '45px',
    paddingRight: '45px',
    borderWidth: '0px',
    backgroundColor: '#F6FCFF',
}));

export const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
    },
    marginTop: 30,
}));

export const DrawerBody = styled('div')(() => ({
    padding: '84px 20px 20px',
    fontSize: '14px',
    '& p': {
        margin: '5px 0 20px',
    },
    '& h3': {
        margin: 0,
    },
}));

export const styleModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    p: 8,
};

export const stylePlantModal = {
    position: 'absolute',
    top: '0%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: 1000,
    bgcolor: 'background.paper',
    p: 8,
};

export const styleDeleteModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 8,
};

export const styleContactModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: 8,
};
