import React, { useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { SelectChangeEvent } from '@mui/material';

const RequestTypeFilter = ({ onFilterChange }: { onFilterChange: (value: string) => void }) => {
    const [selectedIcon, setSelectedIcon] = useState<string>('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value as string;
        setSelectedIcon(value);
        onFilterChange(value);
    };

    return (
        <Select
            sx={{ position: 'absolute', zIndex: 100, top: '10px', left: '9px' }}
            id="requestType"
            autoWidth={false}
            variant="standard"
            value={selectedIcon}
            onChange={handleChange}
        >
            <MenuItem value="">
                <em>none</em>
            </MenuItem>
            <MenuItem value="USR_CHANGE_OFFLINE">
                <ManageAccountsIcon />
            </MenuItem>
            <MenuItem value="USR_CHANGE_ONLINE">
                <ManageAccountsIcon />
            </MenuItem>
            <MenuItem value="INVITE">
                <MarkEmailUnreadIcon />
            </MenuItem>
            <MenuItem value="CONVERT_INVITE">
                <ChangeCircleIcon />
            </MenuItem>
            <MenuItem value="OFFLINE">
                <NoAccountsIcon />
            </MenuItem>
        </Select>
    );
};

export default RequestTypeFilter;
