import { useMsal } from '@azure/msal-react';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { CreateAdHocInviteForm } from '../../../types/invite';

interface UseAdHocInviteState {
    createInvite: (
        //form values for creating the invite
        formValues: CreateAdHocInviteForm,
        //default is online
        type: 'online' | 'offline',
    ) => void;
    isCreatingInvite: boolean;
}

export const useAdHocInvite = (): UseAdHocInviteState => {
    const {
        state: { user, chosenBusinessSegment },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();

    const { trigger: createInvite, isLoading: isCreatingInvite } = useAsyncTrigger<
        unknown,
        Parameters<UseAdHocInviteState['createInvite']>
    >(async (_, formValues, type) => {
        try {
            if (!user || !chosenBusinessSegment?.internalId) {
                throw new Error(
                    'User and chosen business segment are required for creating invite',
                );
            }

            const addHocInvite = await api.adHocInvite.createAdHocInviteByType(
                user,
                chosenBusinessSegment.internalId,
                1,
                formValues,
                generateToken(instance, accounts),
                type,
            );

            if (addHocInvite && type === 'offline') {
                await api.approvalList.approveOfflineEntitlement(
                    user,
                    [addHocInvite.data],
                    generateToken(instance, accounts),
                );
            }

            toast.success('OK');
        } catch (error) {
            if (error instanceof Error) {
                toast.error(error.message);
            } else if (error instanceof AxiosError) {
                const json = JSON.parse(error.response?.data);
                toast.error(json.Detail);
            } else {
                throw new Error(
                    'Unknown error while approving offline entitlemenet' + JSON.stringify(error),
                );
            }
        }
    });

    return { createInvite, isCreatingInvite };
};
