import axios from 'axios';
import { User } from '../../types';
import { toast } from 'react-toastify';
import i18n from 'i18next';

export const createEntitlementApi = (fetchApi: any) => {
    async function handleUserRole(token: any, data: any) {
        return axios
            .post(`api/Configuration/HandleUserRoles`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': 'en-US',
                },
            })
            .then((res: any) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response.status) {
                    const message = i18n.t('errorMessage.ErrorEntitlementList');
                    // toast.error(message)
                } else {
                    const json = JSON.parse(err.response.data);
                    // toast.error(json.Detail);
                }
            });
    }

    function getEntitlementByEmail(loggedUser: User, token: any) {
        return fetchApi(
            `api/EntitlementRequest/GetEntitlementRequestByEmail/${loggedUser.email}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getEntitlementRequestById(entitlementRequestId: number, loggedUser: User, token: any) {
        return fetchApi(
            `api/EntitlementRequest/GetEntitlementRequestById/${entitlementRequestId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getBusinessSegments(loggedUser: User, appId: number | null, token: any) {
        if (loggedUser) {
            if (loggedUser.role === 'ExternalUser') {
                return fetchApi(
                    `api/BusinessSegment/GetBusinessSegmentsForExternalUser/${loggedUser.email}${appId ? '?AppId=' + appId : ''}`,
                    loggedUser.language,
                    'GET',
                    token,
                );
            } else {
                return fetchApi(
                    `api/BusinessSegment/GetBusinessSegmentsForAdminUser/${loggedUser.userIdLidap}${appId ? '?AppId=' + appId : ''}`,
                    loggedUser.language,
                    'GET',
                    token,
                );
            }
        }
    }

    function getUserEntitlement(loggedUser: User, token: any) {
        if (loggedUser) {
            return fetchApi(
                `api/Entitlement/GetUserEntitlement/${loggedUser.id}`,
                loggedUser.language,
                'GET',
                token,
            );
        }
    }

    function getAutoPressByEntitlement(entitlementRequestId: any, loggedUser: User, token: any) {
        return fetchApi(
            `api/BusinessSegment/GetAutoPressBusiness/${entitlementRequestId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function createBusinessSegment(loggedUser: User, token: any, data: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator') {
                return fetchApi(
                    `api/BusinessSegment/CreateBusinessSegment`,
                    loggedUser.language,
                    'POST',
                    data,
                    token,
                );
            }
        }
    }

    function updateBusinessSegment(loggedUser: User, token: any, data: any) {
        if (loggedUser) {
            if (loggedUser.role === 'Administrator' || loggedUser.role === 'SuperAdministrator') {
                return fetchApi(
                    `api/BusinessSegment/UpdateBusinessSegment`,
                    loggedUser.language,
                    'PUT',
                    data,
                    token,
                );
            }
        }
    }

    function getAppInsightTrace(loggedUser: User, page: number, perpage: number, token: any) {
        return fetchApi(`GetAppInsightTrace/${page}/${perpage}`, loggedUser.language, 'GET', token);
    }

    function getAppExceptions(loggedUser: User, page: number, perpage: number, token: any) {
        return fetchApi(`GetAppExceptions/${page}/${perpage}`, loggedUser.language, 'GET', token);
    }

    function createOrgUnitsAppHierarchies(
        loggedUser: User,
        appInfoId: number,
        businessSegmentInternalId: number,
        token: any,
    ) {
        return fetchApi(
            `api/AppHierarchy/CreateOrgUnitsAppHierarchies/${appInfoId}/${businessSegmentInternalId}`,
            loggedUser.language,
            'POST',
            token,
        );
    }

    function createOrgUnitsAppHierarchiesByType(
        loggedUser: User,
        appInfoId: number,
        typeId: number,
        token: any,
    ) {
        return fetchApi(
            `api/AppHierarchy/CreateNoOrgUnitsAppHierarchiesByType/${appInfoId}/${typeId}`,
            loggedUser.language,
            'POST',
            token,
        );
    }

    async function getCreatedInvitesAndSendNotifications(loggedUser: User, token: any) {
        return axios
            .post(`api/EntitlementRequest/GetCreatedInvitesAndSendInvitations`, null, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then(() => {
                toast.success('OK');
            })
            .catch((err) => {
                if (err.response.status) {
                    const message = i18n.t('errorMessage.ErrorEntitlementList');
                    toast.error(message);
                } else {
                    const json = JSON.parse(err.response.data);
                    toast.error(json.Detail);
                }
            });
    }

    async function approveEntitlementRequest(loggedUser: User, token: any, data: any) {
        return axios
            .post(`api/EntitlementRequest/ApproveEntitlementRequest`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then(() => {
                toast.success('OK');
            })
            .catch((err) => {
                if (err.response.status) {
                    const message = i18n.t('errorMessage.ErrorEntitlementList');
                    toast.error(message);
                } else {
                    const json = JSON.parse(err.response.data);
                    toast.error(json.Detail);
                }
            });
    }

    async function flipOfflineToOnline(
        entitlementRequestId: any,
        data: any,
        loggedUser: User,
        token: any,
    ) {
        let userIdLidap = loggedUser.userIdLidap;
        // hack
        if (
            loggedUser.email === 'milan.stefanovic@tron-it.com' ||
            loggedUser.email === 'milan.stefanovic2@linde.com'
        ) {
            userIdLidap = 'uk4111@linde.com';
        }

        return axios
            .post(
                `api/EntitlementRequest/FlipOfflineToOnlineUser?entitlementRequestId=${entitlementRequestId}&adminUserObjectId=${userIdLidap}`,
                data,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: await token,
                        'Accept-Language': loggedUser.language,
                    },
                },
            )
            .then(() => {
                toast.success('OK');
            })
            .catch((err) => {
                if (err.response && err.response.status) {
                    // const message = i18n.t('errorMessage.ErrorEntitlementList')
                    const message = 'Error flipping offline to online';
                    toast.error(message);
                } else if (err.response && err.response.data) {
                    const json = JSON.parse(err.response.data);
                    toast.error(json.Detail);
                } else {
                    // Handle other errors
                    toast.error('An unexpected error occurred');
                }
            });
    }

    function refreshConfig(
        loggedUser: User,
        appInfoId: number,
        businessSegmentInternalId: number,
        token: any,
    ) {
        return fetchApi(
            `api/AppConfiguration/${appInfoId}/${businessSegmentInternalId}`,
            loggedUser.language,
            'POST',
            token,
        );
    }

    function getConfigTypes(loggedUser: User, token: any) {
        return fetchApi(`api/AppConfiguration/GetConfigTypes`, loggedUser.language, 'GET', token);
    }

    function getElevateApplications(loggedUser: User, token: any) {
        return fetchApi(
            `api/AppConfiguration/GetElevateApplications`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    async function getAppInfoByAdminGroupPost(loggedUser: User, token: any, data: any) {
        return axios
            .post(`/api/Entitlement/GetAppInfoByAdminGroup`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res.data;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function getUserInformation(loggedUser: User, token: any, data: any) {
        return axios
            .post(`api/User/GetUserCoreData`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res.data;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    // function getUserInformation(loggedUser: User, token: any) {
    //     return fetchApi(
    //         `api/User/GetUserCoreData/${loggedUser.id}`,
    //         loggedUser.language,
    //         'GET',
    //         token,
    //     );
    // }

    function generateCoreDataChangeLink(loggedUser: User, policyName: string, token: any) {
        return fetchApi(
            `api/User/GenerateCoreDataChangeLink?policyName=${policyName}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    return {
        getEntitlementByEmail,
        getEntitlementRequestById,
        getBusinessSegments,
        getUserEntitlement,
        createBusinessSegment,
        updateBusinessSegment,
        getAppInsightTrace,
        getAppExceptions,
        createOrgUnitsAppHierarchies,
        createOrgUnitsAppHierarchiesByType,
        getCreatedInvitesAndSendNotifications,
        approveEntitlementRequest,
        getConfigTypes,
        getElevateApplications,
        refreshConfig,
        getAppInfoByAdminGroupPost,
        handleUserRole,
        getUserInformation,
        getAutoPressByEntitlement,
        flipOfflineToOnline,
        generateCoreDataChangeLink,
    };
};
