import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfigB2C, msalConfigB2CPosition } from './configs/authConfig';
import { UserProvider } from './context';
import Position from './pages/positions';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const msalB2CInstance = new PublicClientApplication(msalConfigB2C);
const msalB2CInstancePosition = new PublicClientApplication(msalConfigB2CPosition);

root.render(
    // <React.StrictMode>
    <>
        {window.location.pathname === '/elevate/admin/position' ? (
            <UserProvider>
                <BrowserRouter>
                    <MsalProvider instance={msalB2CInstancePosition}>
                        <Position />
                    </MsalProvider>
                </BrowserRouter>
            </UserProvider>
        ) : (
            <UserProvider>
                <BrowserRouter>
                    <MsalProvider instance={msalB2CInstance}>
                        <App />
                    </MsalProvider>
                </BrowserRouter>
            </UserProvider>
        )}
    </>,
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
