import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { CreateAdHocInviteForm } from '../../../types/invite';

interface UserObjectFormValues {
    // track entire process of updating user and fetching userDetailedInfo
    isUpdatingUserObject: boolean;
    updateUserObject: (
        // pass the form values that we'll use to update the userObjectData
        formValues: CreateAdHocInviteForm,
        // pass the userId (selected in "Change User Data" page)
        userId?: number,
        // pass the onComplete callback that we'll use to update the userObjectData in the parent component
        onComplete?: (userObjectData: CreateAdHocInviteForm) => void,
    ) => void;
}
// used for "Change User Data" page
export const useUpdateUserObject = (): UserObjectFormValues => {
    const {
        state: { user },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();

    const { trigger: updateUserObject, isLoading: isUpdatingUserObject } = useAsyncTrigger<
        unknown,
        Parameters<UserObjectFormValues['updateUserObject']>
    >(async (_, formValues, userId, onComplete) => {
        if (!user) {
            throw new Error('User not found');
        }

        const { data: updatedUser } = await api.adHocInvite.updateUserObject(
            user,
            formValues,
            generateToken(instance, accounts),
        );

        await api.adHocInvite.updateCornerstoneUserFromEntitlementRequest(
            user,
            updatedUser,
            generateToken(instance, accounts),
        );

        if (userId && onComplete) {
            const result = await api.adHocInvite.getUserDetailedInfo(
                user,
                userId,
                generateToken(instance, accounts),
            );
            //pass the result outside (e.g if we want to populate form with the userDetailInfo
            onComplete(result);
            return result;
        }
    });
    return { updateUserObject, isUpdatingUserObject };
};
