import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { CornerstoneDataLabel } from '../../../types/cornerstoneDataLabel';

interface UseCornerstoneLabelsState {
    cornerstoneLabels: CornerstoneDataLabel[];
    isCSDataLoading: boolean;
    getCornerstoneLabels: () => void;
}

export const useCornerstoneLabels = (): UseCornerstoneLabelsState => {
    const {
        state: { user, chosenBusinessSegment, appId },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: cornerstoneLabels = [],
        trigger: getCornerstoneLabels,
        isLoading: isCSDataLoading,
    } = useAsyncTrigger(async () => {
        if (!user || !chosenBusinessSegment?.internalId || !appId) {
            throw new Error('User, business segment, and appId must be defined');
        }

        const csData = await api.adHocInvite.getCornerstoneLabels(
            user,
            chosenBusinessSegment.internalId,
            appId,
            generateToken(instance, accounts),
        );

        // show only data that has label
        return csData.filter(({ label }) => label);
    });

    return { cornerstoneLabels, isCSDataLoading, getCornerstoneLabels };
};
