import { AnyFunction } from '../types/util';

/**
 * An object that allows unrestricted member access.
 */
export type IndexableObject<T = unknown> = { [key in string | number]: T };

/**
 * Returns true if the specified value is a valid, non-null, object.
 *
 * This includes arrays too. If you need to exclude arrays you need to use
 * an additional Array.isArray() check.
 *
 * @param value The value to validate.
 */
export function isObject(value: unknown): value is IndexableObject {
    return value !== null && typeof value === 'object';
}

/**
 * Converts an object to a "plain" object (i.e. recursively removes links to non-Object prototypes).
 *
 * Use this to easily get rid of getter-only properties so they can be validated with Joi.
 * Also removes all properties with function values.
 *
 * @param data Value to transform.
 */
export function toPlainObject(data: unknown): unknown {
    if (typeof data === 'function' || typeof data === 'symbol' || data === undefined) {
        return undefined;
    }
    if (!isObject(data) || data instanceof Date) {
        return data;
    }
    if (
        data === globalThis ||
        (typeof globalThis.SharedArrayBuffer === 'function' && data instanceof SharedArrayBuffer) ||
        data instanceof ArrayBuffer ||
        data instanceof Document ||
        data instanceof Element ||
        data instanceof Window ||
        data instanceof Event ||
        data instanceof Navigator
    ) {
        return undefined;
    }
    if (data instanceof RegExp) {
        return data.toString();
    }
    if (data instanceof Map) {
        return Array.from(data.entries(), ([key, value]) => [
            toPlainObject(key),
            toPlainObject(value),
        ]).filter(([key, value]) => key !== undefined && value !== undefined);
    }
    if (data instanceof Set) {
        return Array.from(data.values(), toPlainObject).filter((value) => value !== undefined);
    }
    if (Array.isArray(data)) {
        return data.map(toPlainObject);
    }
    if (hasMethod(data, 'toJSON')) {
        data = data.toJSON();
    }
    if (!isObject(data)) {
        return typeof data === 'function' ? undefined : data;
    }
    const plainCopy: IndexableObject = {};
    for (const key of Object.keys(data)) {
        const value = toPlainObject(data[key]);
        if (value !== undefined) {
            plainCopy[key] = value;
        }
    }
    return plainCopy;
}

/**
 * Returns true if specified method exists (somewhere in prototype chain of,
 * or directly) on the specified object.
 *
 * @param object Object whose method to check.
 * @param method Name of the method to check for.
 */
export function hasMethod<T, Name extends string | symbol | number>(
    object: T,
    method: Name,
): object is T & { [P in Name]: AnyFunction } {
    return isObject(object) && typeof object[method as string] === 'function';
}

/**
 * Parameters for customizing the behavior of equal() function.
 */
export interface EqualityOptions {
    /**
     * Maximum depth equal() will traverse to.
     * If two objects are equal up to the maxDepth level they will be
     * threated as equal even if they differ in the lower levels.
     * Optional, defaults to Infinity (i.e. fully traverse object graphs).
     */
    maxDepth?: number;
    /**
     * Comparator used to test primitive on all, and non-primitive values on
     * the maxDepth level.
     * Either 'loose' which uses == JavaScript operator, 'strict' which uses
     * Object.is() semantics or a custom comparator function.
     */
    compare?: 'loose' | 'strict' | ((a: unknown, b: unknown) => boolean);
}

/**
 * Tests a and b for structural (a.k.a deep) equality.
 *
 * @param a Value to compare to.
 * @param b Value to compare.
 * @param compare
 * @param maxDepth
 */
export function equal(
    a: unknown,
    b: unknown,
    { compare = 'strict', maxDepth = Infinity }: EqualityOptions = {},
) {
    return equalImpl(a, b, {
        cache: new Map(),
        maxDepth,
        compare:
            compare === 'strict'
                ? Object.is
                : compare === 'loose'
                  ? (lhs, rhs) => lhs == rhs
                  : compare,
    });
}

/**
 * Parameters for customizing the behavior of internal equalImpl() function.
 */
interface EqualImplParams {
    /**
     * Comparison cache used for handling circular references.
     */
    cache: Map<object, Set<object>>;
    /**
     * Comparator function.
     */
    compare: (a: unknown, b: unknown) => boolean;
    /**
     * Maximum depth equalImpl() will traverse to.
     * If two objects are equal up to the maxDepth level they will be
     * threated as equal even if they differ in the lower levels.
     */
    maxDepth: number;
}

/**
 * Implementation of the deep equal() semantics.
 *
 * This function recursively tests two objects for equality making sure not
 * to blow up the call stack when handling circular references.
 *
 * @param a Value to compare to.
 * @param b Value to compare.
 * @param params Compare and traverse parameters.
 * @param depth Current depth of traversal.
 */
function equalImpl(a: unknown, b: unknown, params: EqualImplParams, depth = 0): boolean {
    if (++depth > params.maxDepth || !isObject(a) || !isObject(b)) {
        return params.compare(a, b);
    }

    if (a === b) {
        return true;
    }

    let rhsValues = params.cache.get(a);
    if (!rhsValues) {
        rhsValues = new Set();
        params.cache.set(a, rhsValues);
    } else if (rhsValues.has(b)) {
        return false;
    }

    rhsValues.add(b);

    if (a instanceof Date) {
        if (b instanceof Date) {
            return a.getTime() === b.getTime();
        }
        return false;
    } else if (b instanceof Date) {
        return false;
    }

    const objects: [unknown, unknown][] = [];

    if (Array.isArray(a)) {
        const { length } = a;
        if (!Array.isArray(b) || length !== b.length) {
            return false;
        }

        for (let i = 0; i < length; i++) {
            if (typeof a[i] !== 'object' && !params.compare(a[i], b[i])) {
                return false;
            }
            objects.push([a[i], b[i]]);
        }
    } else if (a instanceof Map) {
        if (!(b instanceof Map) || a.size !== b.size) {
            return false;
        }

        for (const [key, value] of a) {
            const bValue = b.get(key);
            if (
                (value !== undefined && b === undefined) ||
                (typeof value !== 'object' && !params.compare(value, bValue))
            ) {
                return false;
            }
            objects.push([value, bValue]);
        }
    } else if (a instanceof Set) {
        if (!(b instanceof Set) || a.size !== b.size) {
            return false;
        }

        for (const item of a) {
            if (!b.has(item)) {
                return false;
            }
        }

        return true;
    } else {
        if (Array.isArray(b) || b instanceof Map || b instanceof Set) {
            return false;
        }

        const keys = Object.keys(a);
        if (Object.keys(b).length !== keys.length) {
            return false;
        }

        for (const key of keys) {
            if (typeof a[key] !== 'object' && !params.compare(a[key], b[key])) {
                return false;
            }
            objects.push([a[key], b[key]]);
        }
    }

    return objects.every(([lhs, rhs]) => equalImpl(lhs, rhs, params, depth));
}
