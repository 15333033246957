import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { UserDataListResponse } from '../../../types/user';

export interface UserDataSearchResult extends Pick<UserDataListResponse, 'id' | 'email'> {
    label: string;
}

interface UseUserDataSearchState {
    isLoadingUserDataSearch: boolean;
    searchUserData: (searchValue: string, onComplete?: () => void) => void;
    userData: UserDataSearchResult[];
}

function mapResponseToOptions(response: UserDataListResponse[]): UserDataSearchResult[] {
    return response.map(({ id, email, firstName, lastName }) => ({
        id,
        label: `${firstName}  ${lastName}  -  (${email})`,
        email,
    }));
}

export const useUserDataSearch = (): UseUserDataSearchState => {
    const {
        state: { user, chosenBusinessSegment },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        trigger: searchUserData,
        isLoading: isLoadingUserDataSearch,
        result: userData = [],
    } = useAsyncTrigger<
        UseUserDataSearchState['userData'],
        Parameters<UseUserDataSearchState['searchUserData']>
    >(async (_, searchValue, onComplete) => {
        if (!user || !chosenBusinessSegment?.internalId) {
            throw new Error('User and chosenBusinessSegment.internalId must be defined');
        }

        const userDataSearchResult = await api.adHocInvite.getUserData(
            user,
            searchValue,
            chosenBusinessSegment.internalId,
            generateToken(instance, accounts),
        );

        if (userDataSearchResult) {
            if (onComplete) {
                onComplete();
            }
            return mapResponseToOptions(userDataSearchResult);
        }

        return [];
    });

    return { searchUserData, isLoadingUserDataSearch, userData };
};
