import React, {
    useContext,
    useState,
    useEffect,
    SyntheticEvent,
    FormEventHandler,
    useRef,
} from 'react';
import { UserContext } from '../../context';
import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { IAdHocInvite, IBodyPassChange } from '../../types';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import { api } from '../../services';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IAutocompleat, ILocation, ISupervisor } from '../../types';
import { Error, InputContainer } from '../../styled';

const defaultValues = {
    FirstName: '',
    LastName: '',
    Email: '',
    Location: '',
    Supervisor: '',
    PhoneNumber: '',
    PositionTitle: '',
    Company: '',
    Language: 'en-US',
};

const AdHocLidap = (props: any) => {
    const { state } = useContext(UserContext);
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const supervisorsInput = useRef<HTMLElement>(null);
    const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
    const [isSupervisorsLoading, setIsSupervisorsLoading] = useState<boolean>(false);
    const [locations, setLocations] = useState<IAutocompleat[]>([]);
    const [supervisors, setSupervisors] = useState<IAutocompleat[]>([]);
    const [supervisorsInputValue, setSupervisorsInputValue] = useState<string>('');
    const [formValues, setFormValues] = useState<IAdHocInvite>(defaultValues);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const userInput = useRef<HTMLElement>(null);
    const [userObject, setUserObject] = useState<IBodyPassChange>();

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidPhoneNumber(number: any) {
        return /^[0-9 -+]+$/.test(number);
    }

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
        });
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleRequiredInput = (event: any) => {
        event.preventDefault();
        if (formValues.FirstName.trim().length === 0) {
            let errorName: any = document.getElementById('FirstNameError');
            errorName.style.display = 'inline';
        }
        if (formValues.LastName.trim().length === 0) {
            let errorLast: any = document.getElementById('LastNameError');
            errorLast.style.display = 'inline';
        }
        if (formValues.Email.trim().length === 0) {
            let errorEmail: any = document.getElementById('EmailError');
            errorEmail.style.display = 'inline';
        }
        if (!isValidEmail(formValues.Email) && formValues.Email.trim().length !== 0) {
            let errorEmailInvalid: any = document.getElementById('EmailInvalid');
            errorEmailInvalid.style.display = 'inline';
        }
        if (formValues.Location.trim().length === 0) {
            let errorLocation: any = document.getElementById('LocationError');
            errorLocation.style.display = 'inline';
        }
        if (formValues.Supervisor.trim().length === 0) {
            let errorSupervisor: any = document.getElementById('SupervisorError');
            errorSupervisor.style.display = 'inline';
        }
        if (formValues.PhoneNumber === '') {
            let errorPhone: any = document.getElementById('PhoneError');
            errorPhone.style.display = 'inline';
        }
        if (formValues.PhoneNumber !== '' && !isValidPhoneNumber(formValues.PhoneNumber)) {
            let errorPhoneInvalid: any = document.getElementById('PhoneInvalid');
            errorPhoneInvalid.style.display = 'inline';
        }
    };

    const handleEmptyInputs = () => {
        if (
            formValues.FirstName === '' ||
            formValues.LastName === '' ||
            formValues.Email === '' ||
            formValues.Location === '' ||
            formValues.PhoneNumber === '' ||
            formValues.Supervisor === '' ||
            formValues.PhoneNumber === '' ||
            formValues.FirstName.trim().length === 0 ||
            formValues.LastName.trim().length === 0 ||
            !isValidPhoneNumber(formValues.PhoneNumber)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (event: any) => {
        if (handleEmptyInputs() === false) {
            event.preventDefault();
            setIsSubmitLoading(true);
            api.adHocInvite
                .createAdHocInvite(state.user, formValues, generateToken(instance, accounts))
                .then((res) => {
                    if (res === null) {
                        setIsSubmitLoading(false);
                        return;
                    }
                    setIsSubmitLoading(false);
                    toast.success('OK');
                });
        } else {
            handleRequiredInput(event);
        }
    };

    const handleAutocompleteChange = (value: string | IAutocompleat | null, name: string) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
        });
        if (typeof value !== 'object') return;
        setFormValues({
            ...formValues,
            [name]: value?.id || '',
        });
    };

    const getSupervisors = () => {
        setIsSupervisorsLoading(true);
        api.adHocInvite
            .getSupervisors(state.user, supervisorsInputValue, generateToken(instance, accounts))
            .then((res: ISupervisor[]) => {
                console.log(res);
                if (!res) return;
                setIsSupervisorsLoading(false);
                setSupervisors(
                    res.map((supervisor) => ({
                        id: supervisor.number,
                        label: `${supervisor.fullName} (${supervisor.number})${
                            supervisor.email ? ' - ' + supervisor.email : ''
                        }`,
                    })),
                );
                supervisorsInput.current?.focus();
            });
    };

    const languages: any = [
        { id: 'en-US', label: 'en-US' },
        { id: 'fr-FR', label: 'fr-FR' },
        { id: 'de-DE', label: 'de-DE' },
    ];

    useEffect(() => {
        if (!state.user || !state.locationCode || locations.length) return;
        setIsLocationsLoading(true);
        api.adHocInvite
            .getLocations(state.user, state.locationCode, generateToken(instance, accounts))
            .then((res: ILocation[]) => {
                if (!res) return;
                setIsLocationsLoading(false);
                setLocations(
                    res.map((loc) => ({
                        id: loc.code,
                        label: loc.name + ' - ' + loc.code,
                    })),
                );
            });
    }, [state.user, state.locationCode]);

    let timeout: any = null;
    const handleUsersChange = (event: any) => {
        if (props.page === 'change-data') {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (event.target.value !== undefined && event.target.value.length > 3) {
                    setIsLoading(true);
                    state.chosenBusinessSegment.internalId &&
                        api.adHocInvite
                            .getUserData(
                                state.user,
                                event.target.value,
                                state.chosenBusinessSegment.internalId,
                                generateToken(instance, accounts),
                            )
                            ?.then((res: any) => {
                                if (!res) return;
                                setIsLoading(false);
                                setUsersData(
                                    res.map((supervisor: any) => ({
                                        id: supervisor.userObjectId,
                                        label: `${supervisor.firstName}  ${supervisor.lastName}  -  (${supervisor.email})`,
                                        email: supervisor.email,
                                    })),
                                );
                                supervisorsInput.current?.focus();
                            });
                }
            }, 300);
        }
    };

    return (
        <>
            {props.page === 'change-data' && (
                <>
                    <InputContainer>
                        <InputLabel htmlFor="location">{t('label.SearchUser')}</InputLabel>
                        <Autocomplete
                            openOnFocus
                            disablePortal
                            id="combo-box-demo"
                            options={usersData}
                            freeSolo={true}
                            onInputChange={(event) => handleUsersChange(event)}
                            onChange={(event: any, newValue: string | null | any) =>
                                newValue && setUserObject(newValue)
                            }
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} inputRef={userInput} variant="standard" />
                            )}
                        />
                        {isLoading && <LinearProgress />}
                        <p
                            style={{
                                margin: 0,
                                fontSize: '11px',
                                color: 'gray',
                                marginBottom: '70px',
                            }}
                        >
                            {t('label.threeOrMore')}
                        </p>
                    </InputContainer>
                </>
            )}

            <form onSubmit={handleSubmit}>
                <Box sx={{ width: 'calc(100vh-30px)', margin: '20px' }}>
                    <div>
                        <h3>{'AdHoc Invites'}</h3>
                    </div>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t('formField.firstName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="FirstNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="FirstNameInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-name"
                                    name="FirstName"
                                    value={formValues.FirstName}
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-last-name">
                                    {t('formField.lastName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="LastNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="LastNameInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-last-name"
                                    name="LastName"
                                    value={formValues.LastName}
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    {t('formField.email')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-email"
                                    name="Email"
                                    value={formValues.Email}
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            {isLocationsLoading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="user-location">
                                    {t('formField.LocationCode')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="LocationError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="LocationInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={locations}
                                    onChange={(event, value) =>
                                        handleAutocompleteChange(value, 'Location')
                                    }
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item alignItems="center" container xs={6} sx={{ marginBottom: 'px' }}>
                            <Grid item xs>
                                <InputContainer>
                                    <InputLabel htmlFor="user-supervisor">
                                        {t('formField.SupervisorNumber')}
                                        <span style={{ color: 'red' }}> *</span>
                                        <Error className="errorMsg" id="SupervisorError">
                                            {t('errorMessage.RequiredField')}
                                        </Error>
                                        <Error className="errorMsg" id="SupervisorInvalid">
                                            {t('errorMessage.InvalidInput')}
                                        </Error>
                                    </InputLabel>
                                    <Autocomplete
                                        openOnFocus
                                        disablePortal
                                        id="combo-box-demo"
                                        options={supervisors}
                                        freeSolo={true}
                                        onInputChange={(event, value) =>
                                            setSupervisorsInputValue(value)
                                        }
                                        onChange={(event, value) =>
                                            handleAutocompleteChange(value, 'Supervisor')
                                        }
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                inputRef={supervisorsInput}
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </InputContainer>
                            </Grid>
                            {supervisorsInputValue.length > 2 && (
                                <IconButton onClick={getSupervisors}>
                                    <CachedIcon />
                                </IconButton>
                            )}
                            <Grid item xs={12} sx={{ position: 'relative' }}>
                                {isSupervisorsLoading && (
                                    <LinearProgress
                                        sx={{
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            bottom: 10,
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-phone">
                                    {t('formField.telephone')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="PhoneError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="PhoneInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-phone"
                                    name="PhoneNumber"
                                    value={formValues.PhoneNumber}
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-title">
                                    {t('formField.PositionTitle')}
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-title"
                                    name="PositionTitle"
                                    value={formValues.PositionTitle}
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-company">
                                    {t('formField.myCompany')}
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-company"
                                    name="Company"
                                    value={formValues.Company}
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-title">Language</InputLabel>
                                <Autocomplete
                                    openOnFocus
                                    disablePortal
                                    defaultValue={{
                                        id: 'en-US',
                                        label: 'en-US',
                                    }}
                                    id="combo-box-demo"
                                    options={languages}
                                    onChange={(event, value) =>
                                        handleAutocompleteChange(value, 'Language')
                                    }
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={4} sx={{ marginTop: '10px' }}>
                        {isSubmitLoading ? (
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
                                <CircularProgress sx={{ width: '100%' }} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Button
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="outlined"
                                    >
                                        {'Save'}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </form>
        </>
    );
};

export default AdHocLidap;
