import { Box, LinearProgress, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'react-moment';
import jstz from 'jstz';
import { useTranslation } from 'react-i18next';

const customGridProps = {
    filterPanel: {
        columnsSort: 'asc',
        filterFormProps: {
            linkOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
            },
            columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
            },
            operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
            },
            valueInputProps: {
                InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                },
            },
            deleteIconProps: {
                sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                },
            },
        },
        sx: {
            '& .MuiDataGrid-filterForm': { p: 2 },
            '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 5 },
            '& .MuiDataGrid-filterFormColumnInput': { mr: 5, width: 150 },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: 5 },
            '& .MuiDataGrid-filterFormValueInput': { width: 200 },
        },
    },
};

const AppInsights = ({ data, loading }: any) => {
    const timezone = jstz.determine();
    const timezoneName = timezone.name();
    const { t } = useTranslation();

    const appInsights =
        data &&
        data.map((data: any, index: number) => {
            return {
                timeStamp: data.timeStamp,
                message: data.message,
                innerMessage: data.innerMessage,
                id: index,
            };
        });

    const columns = [
        {
            field: 'timeStamp',
            headerName: t('label.TimeStamp'),
            width: 220,
            renderCell: (params: any) => (
                <Moment format="YYYY/MM/DD HH:mm" local>
                    {params.formattedValue.toLocaleString(timezoneName)}
                </Moment>
            ),
        },
        {
            field: 'message',
            headerName: t('label.Message'),
            width: 550,
            renderCell: (params: any) => (
                <Tooltip title={params.formattedValue}>
                    <span
                        className="table-cell-trucate"
                        style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                    >
                        {params.formattedValue}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'innerMessage',
            headerName: t('label.InnerMessage'),
            width: 2000,
            renderCell: (params: any) => (
                <Tooltip title={params.formattedValue}>
                    <span
                        className="table-cell-trucate"
                        style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                    >
                        {params.formattedValue}
                    </span>
                </Tooltip>
            ),
        },
    ];

    return loading ? (
        <LinearProgress />
    ) : (
        <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
            {data && (
                <DataGrid
                    rows={appInsights}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[10]}
                    componentsProps={customGridProps}
                    sx={{ borderStyle: 'none' }}
                />
            )}
        </Box>
    );
};

export default AppInsights;
