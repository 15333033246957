import React, { useContext, useEffect, useState, useRef } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { api } from '../../services';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import CheckIcon from '@mui/icons-material/Check';
import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    LinearProgress,
    Autocomplete,
    FormControl,
    MenuItem,
    TextField,
    FormLabel,
    Button,
    Modal,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import { b2cPolicies } from '../../configs/authConfig.Development';
import { UserContext } from '../../context';
import { useMsal } from '@azure/msal-react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
    DataGrid,
    GridActionsCellItem,
    GridApi,
    GridCellValue,
    GridColDef,
    GridRenderCellParams,
    selectedGridRowsSelector,
} from '@mui/x-data-grid';

import SaveIcon from '@mui/icons-material/Save';
import Home from '../home';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};
const dataTodisplay = [
    {
        cpid: '00046859',
        firstname: 'Fred',
        lastName: 'Bloggs',
        email: ' fred.bloggs@linde.com',
        posId: 'Lead SAP Developer',
        posName: '00046859',
        ccId: 'Lead SAP Developer',
        ccName: '00046859',
    },
];

const InputContainer = styled('div')(() => ({
    marginBottom: '10px',
}));
const rows = [
    {
        id: 1,
        Position: '',
        lastName: 'Snow',
        firstName: 'Jon',
        email: 'jon.snow@linde.com',
        lindeuserid: 'UK123',
        cpid: '123',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 2,
        Position: '',
        lastName: 'Lannister',
        firstName: 'Cersei',
        email: 'cersei.lannister@linde.com',
        lindeuserid: 'UK124',
        cpid: '124',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 3,
        Position: '',
        lastName: 'Lannister',
        firstName: 'Jaime',
        email: 'lannister.jaime@linde.com',
        lindeuserid: 'UK125',
        cpid: '125',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 4,
        Position: '',
        lastName: 'Stark',
        firstName: 'Arya',
        email: 'stark.arya@linde.com',
        lindeuserid: 'UK126',
        cpid: '126',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 5,
        Position: '',
        lastName: 'Targaryen',
        firstName: 'Daenerys',
        email: 'targaryen.daenerys@linde.com',
        lindeuserid: 'UK127',
        cpid: '127',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 6,
        Position: '',
        lastName: 'Melisandre',
        firstName: 'Cersei',
        email: 'melisandre.cersei@linde.com',
        lindeuserid: 'UK126',
        cpid: '126',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },

    {
        id: 10,
        Position: '',
        lastName: 'Panwar',
        firstName: 'Jon',
        email: 'jon.panwar@linde.com',
        lindeuserid: 'UK127',
        cpid: '127',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },

    {
        id: 11,
        Position: '',
        lastName: 'Dragan',
        firstName: 'Jon',
        email: 'jon.dragan@linde.com',
        lindeuserid: 'UK128',
        cpid: '128',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 18286,
        Position: 'Manager',
        lindeuserid: 'DE23A3',
        firstName: 'Panwar',
        lastName: 'Hust',
        email: 'Panwar.HUST@LINDE.COM',
        cpid: '00176124',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 18287,
        Position: 'Manager',
        lindeuserid: 'DE23A2',
        firstName: 'Tobias',
        lastName: 'Hust',
        email: 'TOBIAS.HUST@LINDE.COM',
        cpid: '00176123',
        userMgrId: '',
        userMgrFirstName: '',
        userMgrLastName: '',
        userMgrEmail: '',
    },
    {
        id: 16587,
        Position: '',
        lindeuserid: 'DE2AFA',
        firstName: 'Dumitru',
        lastName: 'Ozunu',
        email: 'DUMITRU.OZUNU@LINDE.COM',
        cpid: '00248232',
        userMgrId: 'DE23A2',
        userMgrFirstName: 'Tobias',
        userMgrLastName: 'Hust',
        userMgrEmail: 'TOBIAS.HUST@LINDE.COM',
    },
    {
        id: 16954,
        Position: '',
        lindeuserid: 'UK4111',
        firstName: 'Ciaran',
        lastName: 'McGuire',
        email: 'CIARAN.MCGUIRE@LINDE.COM',
        cpid: '00057905',
        userMgrId: 'DE23A2',
        userMgrFirstName: 'Tobias',
        userMgrLastName: 'Hust',
        userMgrEmail: 'TOBIAS.HUST@LINDE.COM',
    },
];
const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 150,
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        width: 150,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        width: 150,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 250,
    },
    {
        field: 'lindeuserid',
        headerName: 'Linde User ID',
        width: 150,
    },
    {
        field: 'cpid',
        headerName: 'CPID',
        width: 150,
    },
];
const customGridProps = {
    filterPanel: {
        columnsSort: 'asc',
        filterFormProps: {
            linkOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
            },
            columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
            },
            operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
            },
            valueInputProps: {
                InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                },
            },
            deleteIconProps: {
                sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                },
            },
        },
        sx: {
            '& .MuiDataGrid-filterForm': { p: 2 },
            '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 5 },
            '& .MuiDataGrid-filterFormColumnInput': { mr: 5, width: 150 },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: 5 },
            '& .MuiDataGrid-filterFormValueInput': { width: 200 },
        },
    },
};
const Position = () => {
    const [open, setOpen] = useState(false);
    const { instance, accounts, inProgress } = useMsal();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { state, dispatch } = useContext(UserContext);
    const [demoApiCall, setDemoApiCall] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filteredRows, setHandleSearch] = useState(rows);
    const [filteredEmployeeOfSelectedManagerRows, setEmployeeOfSelectedManager] = useState(rows);
    const [isGridVisible, setIsGridVisible] = useState<boolean>(false);
    const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
    const [isNoDataVisible, setIsNoDataVisible] = useState<boolean>(false);
    const [isMgrGridVisible, setIsMgrGridVisible] = useState<boolean>(false);
    const [isMgrEmpGridVisible, setIsMgrEmpGridVisible] = useState<boolean>(false);
    const [selectedFirstName, setSelectedFirstName] = useState('');
    const [selectedLindeUserId, setSelectedLindeUserId] = useState('');
    const [selectedLastName, setSelectedLastName] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedCPID, setSelectedCPID] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        if (accounts.length == 0 && inProgress === 'startup') {
            instance.loginRedirect({
                authority: b2cPolicies.authorities.lindePortalSignInAAD.authority,
                scopes: ['openid', 'profile'],
                redirectUri: '/elevate/admin/position',
            });
        } else {
            if (instance && accounts) {
                let availableAccount: AccountInfo[] = accounts;
                let availableTokenClaims: any;
                if (availableAccount && availableAccount.length > 0) {
                    availableTokenClaims = availableAccount[0].idTokenClaims;
                    setUserEmail(
                        availableTokenClaims.mail
                            ? availableTokenClaims.mail
                            : availableTokenClaims.email
                              ? availableTokenClaims.email
                              : '',
                    );
                }
            }
        }
    }, [userEmail]);

    function makeCiaranHappy() {
        alert('This is chil function');
    }
    const handleOpenWindow = () => {
        //alert("calling makeCiaranHappy function..");
        const name = (document.getElementById('name') as HTMLInputElement).value;
        const email = (document.getElementById('email') as HTMLInputElement).value;
        const lindeuserid = (document.getElementById('lindeuserid') as HTMLInputElement).value;
        const cpidValue = (document.getElementById('lblcpid') as HTMLInputElement).value;
        const position = (document.getElementById('position') as HTMLInputElement).value;
        const costsenter = (document.getElementById('cost-center') as HTMLInputElement).value;
        var jsonData = {
            cpid: cpidValue,
            fname: name.split(' ')[0],
            lname: name.split(' ')[1],
            email: email,
            position: position,
            ccname: costsenter,
        };
        window.opener.postMessage(jsonData, 'https://linde-stg.csod.com/catalog/CustomPage.aspx');
        window.close();
    };
    const clearSearch = () => {
        setIsDetailsVisible(false);
        setIsGridVisible(false);
        setIsNoDataVisible(false);
        setIsMgrGridVisible(false);
        setIsMgrEmpGridVisible(false);
        (document.getElementById('firstNametxt') as HTMLInputElement).value = '';
        (document.getElementById('lastName') as HTMLInputElement).value = '';
        (document.getElementById('emailid') as HTMLInputElement).value = '';
        (document.getElementById('cpid') as HTMLInputElement).value = '';
    };
    const handleSearch = () => {
        setIsDetailsVisible(false);
        setIsGridVisible(false);
        setIsNoDataVisible(false);
        setIsMgrGridVisible(false);
        setIsMgrEmpGridVisible(false);

        const radSelectedValue = (
            document.querySelector(
                '[name="radio-buttons-group-emptype"]:checked',
            ) as HTMLInputElement
        ).value;

        const firstNameValue = (document.getElementById('firstNametxt') as HTMLInputElement).value;
        const lastNameValue = (document.getElementById('lastName') as HTMLInputElement).value;
        const emailidValue = (document.getElementById('emailid') as HTMLInputElement).value;
        const cpidValue = (document.getElementById('cpid') as HTMLInputElement).value;
        const lindeUserIdValue = (document.getElementById('lindeUserId') as HTMLInputElement).value;

        const filteredRow = rows.filter((item) => {
            if (radSelectedValue == 'manager') {
                return (
                    item.firstName.toString().toLowerCase().indexOf(firstNameValue.toLowerCase()) >
                        -1 &&
                    item.lastName.toLowerCase().indexOf(lastNameValue.toLowerCase()) > -1 &&
                    item.lindeuserid.toLowerCase().indexOf(lindeUserIdValue.toLowerCase()) > -1 &&
                    item.email.toLowerCase().indexOf(emailidValue.toLowerCase()) > -1 &&
                    item.cpid.toLowerCase().indexOf(cpidValue.toLowerCase()) > -1 &&
                    item.Position.toLowerCase().indexOf('manager') > -1
                );
            } else {
                return (
                    item.firstName.toString().toLowerCase().indexOf(firstNameValue.toLowerCase()) >
                        -1 &&
                    item.lastName.toLowerCase().indexOf(lastNameValue.toLowerCase()) > -1 &&
                    item.email.toLowerCase().indexOf(emailidValue.toLowerCase()) > -1 &&
                    item.cpid.toLowerCase().indexOf(cpidValue.toLowerCase()) > -1 &&
                    item.lindeuserid.toLowerCase().indexOf(lindeUserIdValue.toLowerCase()) > -1
                );
            }
        });

        if (radSelectedValue == 'manager') {
            setIsMgrGridVisible(true);
            if (filteredRow.length == 1) {
                var managerdetails =
                    filteredRow[0].lindeuserid +
                    ' / ' +
                    filteredRow[0].firstName +
                    ' ' +
                    filteredRow[0].lastName +
                    ' / ' +
                    filteredRow[0].email;
                setSelectedManager(managerdetails);
                const arr = rows.filter((_item) => {
                    return _item.userMgrId == filteredRow[0].lindeuserid;
                });
                setEmployeeOfSelectedManager(arr);
                setIsMgrEmpGridVisible(true);
                setIsMgrGridVisible(false);
            }
        } else {
            if (filteredRow.length == 1) {
                setSelectedFirstName(filteredRow[0].firstName);
                setSelectedLastName(filteredRow[0].lastName);
                setSelectedEmail(filteredRow[0].email);
                setSelectedCPID(filteredRow[0].cpid);
                setSelectedLindeUserId(filteredRow[0].lindeuserid);
                setIsDetailsVisible(true);
            } else if (filteredRow.length > 1) setIsGridVisible(true);
        }

        if (filteredRow.length == 0 && radSelectedValue != 'manager') setIsNoDataVisible(true);
        setHandleSearch(filteredRow);
    };

    return (
        <div style={{ padding: '28px' }}>
            {userEmail}
            <FormControl style={{ paddingTop: '28px' }}>
                <FormLabel id="demo-radio-buttons-group-label">Search Employee</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="user"
                    name="radio-buttons-group-emptype"
                    row
                >
                    <FormControlLabel value="user" control={<Radio />} label="By Employee" />
                    <FormControlLabel value="manager" control={<Radio />} label="By Manager" />
                </RadioGroup>

                <Grid container spacing={2} columns={28} sx={{ marginTop: '28px' }}>
                    <Grid item xs={4}>
                        <TextField
                            id="firstNametxt"
                            label="First Name"
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="lastName"
                            label="Last Name"
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="emailid" label="Email" size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="lindeUserId"
                            label="Linde User ID"
                            size="small"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="cpid" label="CPID" size="small" variant="outlined" />
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="outlined" onClick={handleSearch}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>
            {isNoDataVisible ? (
                <Box sx={{ height: '20px', width: '100%', marginTop: '28px' }}>
                    No Employee found.
                </Box>
            ) : (
                <div></div>
            )}

            {isGridVisible ? (
                <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '28px',
                            marginBottom: '28px',
                            padding: '10px',
                            backgroundColor: '#005591',
                            color: 'white',
                        }}
                    >
                        Employee List
                    </Box>
                    {isLoading ? (
                        <LinearProgress />
                    ) : filteredRows.length == 0 ? (
                        <Box
                            sx={{
                                height: '20px',
                                width: '100%',
                                marginTop: '28px',
                            }}
                        >
                            No employee found.
                        </Box>
                    ) : (
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            columnVisibilityModel={{
                                id: false,
                            }}
                            pageSize={50}
                            rowsPerPageOptions={[10]}
                            componentsProps={customGridProps}
                            sx={{ borderStyle: 'none' }}
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) => row.id == Number(ids));
                                setSelectedFirstName(selectedRowData[0].firstName);
                                setSelectedLastName(selectedRowData[0].lastName);
                                setSelectedEmail(selectedRowData[0].email);
                                setSelectedCPID(selectedRowData[0].cpid);
                                setSelectedLindeUserId(selectedRowData[0].lindeuserid);
                                setIsDetailsVisible(true);
                                setIsGridVisible(false);
                            }}
                        />
                    )}
                </Box>
            ) : (
                <div></div>
            )}

            {isMgrGridVisible ? (
                <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '28px',
                            marginBottom: '28px',
                            padding: '10px',
                            color: 'white',
                            backgroundColor: '#005591',
                        }}
                    >
                        Manager List
                    </Box>
                    {isLoading ? (
                        <LinearProgress />
                    ) : filteredRows.length == 0 ? (
                        <Box
                            sx={{
                                height: '20px',
                                width: '100%',
                                marginTop: '28px',
                            }}
                        >
                            No Manager found.
                        </Box>
                    ) : (
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            pageSize={50}
                            columnVisibilityModel={{
                                id: false,
                            }}
                            rowsPerPageOptions={[10]}
                            componentsProps={customGridProps}
                            sx={{ borderStyle: 'none' }}
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) => row.id == Number(ids));
                                var managerdetails =
                                    selectedRowData[0].lindeuserid +
                                    ' / ' +
                                    selectedRowData[0].firstName +
                                    ' ' +
                                    selectedRowData[0].lastName +
                                    ' / ' +
                                    selectedRowData[0].email;
                                setSelectedManager(managerdetails);
                                const arr = rows.filter((_item) => {
                                    return _item.userMgrId == selectedRowData[0].lindeuserid;
                                });
                                setEmployeeOfSelectedManager(arr);
                                setIsMgrEmpGridVisible(true);
                                setIsMgrGridVisible(false);
                            }}
                        />
                    )}
                </Box>
            ) : (
                <div></div>
            )}
            {isMgrEmpGridVisible ? (
                <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '28px',
                            marginBottom: '28px',
                            padding: '10px',
                            color: 'white',
                            backgroundColor: '#005591',
                        }}
                    >
                        Employee of Manager "{selectedManager}"{' '}
                    </Box>
                    {isLoading ? (
                        <LinearProgress />
                    ) : filteredEmployeeOfSelectedManagerRows.length == 0 ? (
                        <Box
                            sx={{
                                height: '20px',
                                width: '100%',
                                marginTop: '28px',
                            }}
                        >
                            No Employee found.
                        </Box>
                    ) : (
                        <DataGrid
                            rows={filteredEmployeeOfSelectedManagerRows}
                            columns={columns}
                            pageSize={50}
                            columnVisibilityModel={{
                                id: false,
                            }}
                            rowsPerPageOptions={[10]}
                            componentsProps={customGridProps}
                            sx={{ borderStyle: 'none' }}
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) => row.id == Number(ids));
                                setSelectedFirstName(selectedRowData[0].firstName);
                                setSelectedLastName(selectedRowData[0].lastName);
                                setSelectedEmail(selectedRowData[0].email);
                                setSelectedCPID(selectedRowData[0].cpid);
                                setSelectedLindeUserId(selectedRowData[0].lindeuserid);
                                setIsDetailsVisible(true);
                                setIsMgrEmpGridVisible(false);
                            }}
                        />
                    )}
                </Box>
            ) : (
                <div></div>
            )}
            {isDetailsVisible ? (
                <Box sx={{ height: 'calc(100vh - 220px)', width: '100%' }}>
                    <Grid container columnSpacing={4} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="name">Name</InputLabel>
                                <Input
                                    fullWidth
                                    readOnly
                                    id="name"
                                    value={selectedFirstName + ' ' + selectedLastName}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Input fullWidth readOnly id="email" value={selectedEmail} />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="lindeuserid">Linde User ID</InputLabel>
                                <Input
                                    fullWidth
                                    readOnly
                                    id="lindeuserid"
                                    value={selectedLindeUserId}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="cpid">CPID</InputLabel>
                                <Input fullWidth readOnly id="lblcpid" value={selectedCPID} />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="position">Position</InputLabel>
                                <Input
                                    fullWidth
                                    readOnly
                                    id="position"
                                    value="46362268 (Lead SAP Developer)"
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="cost centre">Cost Centre</InputLabel>
                                <Input
                                    fullWidth
                                    readOnly
                                    id="cost-center"
                                    value="DE50-0007001987 (SAP Development)"
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%' }}>
                        <Button startIcon={<SaveIcon />} variant="outlined" onClick={handleOpen}>
                            Send Data to Elevate
                        </Button>
                        <Button
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            onClick={handleOpenWindow}
                            sx={{ marginLeft: '20px' }}
                        >
                            Send Data to Elevate 2
                        </Button>
                    </div>
                </Box>
            ) : (
                <div></div>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {dataTodisplay.map((item, index) => (
                        <div>
                            <div key={item.cpid}>CPID : {selectedCPID}</div>
                            <br />
                            <div>First name : {selectedFirstName}</div>
                            <br />
                            <div>Last name : {selectedLastName}</div>
                            <br />
                            <div>Email : {selectedEmail}</div>
                            <br />
                            <div>Position ID : {item.posId}</div>
                            <br />
                            <div>Position : {item.posName}</div>
                            <br />
                            <div>Cost Center ID : {item.ccId}</div>
                            <br />
                            <div>Cost Center Name : {item.ccName}</div>
                            <br />
                        </div>
                    ))}
                </Box>
            </Modal>
        </div>
    );
};

export default Position;
