import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { MandatoryField } from '../../../types/mandatoryField';

interface UseMandatoryFieldsState {
    mandatoryFields?: MandatoryField;
    isMandatoryLoading: boolean;
    getMandatoryFields: (isOnline: boolean) => void;
}

export const useMandatoryFields = (): UseMandatoryFieldsState => {
    const {
        state: { user, chosenBusinessSegment },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: mandatoryFields,
        trigger: getMandatoryFields,
        isLoading: isMandatoryLoading,
    } = useAsyncTrigger<
        UseMandatoryFieldsState['mandatoryFields'],
        Parameters<UseMandatoryFieldsState['getMandatoryFields']>
    >(async (_, isOnline: boolean) => {
        if (!user || !chosenBusinessSegment?.internalId) {
            throw new Error('User and business segment must be defined');
        }

        return await api.adHocInvite.getMandatoryFields(
            user,
            chosenBusinessSegment.internalId,
            1,
            generateToken(instance, accounts),
            isOnline,
        );
    });

    return { mandatoryFields, isMandatoryLoading, getMandatoryFields };
};
