import { useCallback, useEffect, useRef } from 'react';
import { useStableEventHandler } from './useStableEventHandler';

export function useDebouncedCallback(callback: (...params: any[]) => void, delayMs: number) {
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const lastArgs = useRef<any[]>([]);
    const lastStartTime = useRef(Date.now());

    const stableCallback = useStableEventHandler(callback);

    const debouncedCallback = useCallback(
        (...args: any[]) => {
            if (timer.current !== null) {
                clearTimeout(timer.current);
            }
            lastStartTime.current = Date.now();
            lastArgs.current = args;
            timer.current = setTimeout(() => stableCallback(...args), delayMs);
        },
        [delayMs, stableCallback],
    );

    useEffect(() => {
        if (timer.current !== null) {
            clearTimeout(timer.current);
            const elapsedTime = Date.now() - lastStartTime.current;
            if (elapsedTime >= delayMs) {
                stableCallback(...lastArgs.current);
                timer.current = null;
            } else {
                timer.current = setTimeout(
                    () => stableCallback(...lastArgs.current),
                    delayMs - elapsedTime,
                );
            }
        }
    }, [delayMs, stableCallback]);

    useEffect(() => {
        return () => {
            if (timer.current !== null) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    return debouncedCallback;
}
