import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import type { IBusinessRelationship } from '../../../types';

interface ExternalPositionOption {
    id: IBusinessRelationship['internalId'];
    label: IBusinessRelationship['name'];
    externalId: IBusinessRelationship['externalId'];
}

function mapPositionsToOptions(positions: IBusinessRelationship[]): ExternalPositionOption[] {
    return positions.map(({ internalId: id, name: label, externalId }) => ({
        externalId,
        id,
        label,
    }));
}

interface UseExternalPositionsState {
    externalPositions: ExternalPositionOption[];
    isExternalPositionsLoading: boolean;
    getExternalPositions: () => void;
}

export const useExternalPositions = (): UseExternalPositionsState => {
    const {
        state: { user, chosenBusinessSegment },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: externalPositions = [],
        trigger: getExternalPositions,
        isLoading: isExternalPositionsLoading,
    } = useAsyncTrigger(async () => {
        if (!user || !chosenBusinessSegment?.internalId) {
            throw new Error('User and business segment must be defined');
        }

        const externalPositionsResponse = await api.adHocInvite.getExternalPositions(
            user,
            chosenBusinessSegment.internalId,
            generateToken(instance, accounts),
        );
        console.log('externalPositionsResponse', externalPositionsResponse);

        return mapPositionsToOptions(externalPositionsResponse);
    });

    return { externalPositions, isExternalPositionsLoading, getExternalPositions };
};
