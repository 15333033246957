import { toast } from 'react-toastify';

async function call(url: string, language: string, type: string, token: any, data: any) {
    const authorization = `${await token}`;
    let params: any = {
        method: type,
        headers: {
            authorization: authorization,
            'Content-Type': 'application/json',
            'Accept-Language': language,
        },
    };

    let error = false;

    if (data) params.body = JSON.stringify(data);
    return fetch(`${url}`, params)
        .then((response) => {
            if (!response.ok) error = true;
            return response.json();
        })
        .then((json) => {
            if (error) {
                const _json = JSON.parse(json);
                toast.error(_json.Detail);
                return null;
            } else {
                return json;
            }
        })
        .catch((err) => {
            toast.error(err);
        });
}

export const fetchApi = (url: string, language: string, type: string, token?: any, data?: any) => {
    return call(url, language, type, token, data);
};
