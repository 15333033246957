export const countries = [
    { value: 'BRA', label: 'Brazil' },
    { value: 'ARG', label: 'Argentina' },
    { value: 'BOL', label: 'Bolivia' },
    { value: 'CHL', label: 'Chile' },
    { value: 'PRY', label: 'Paraguay' },
    { value: 'PER', label: 'Peru' },
    { value: 'URY', label: 'Uruguay' },
    { value: 'FRA', label: 'France' },
    { value: 'DEU', label: 'Germany' },
    { value: 'BGD', label: 'Bangladesh' },
    { value: 'IND', label: 'India' },
    { value: 'IDN', label: 'Indonesia' },
    { value: 'MYS', label: 'Malaysia' },
    { value: 'PHL', label: 'Philippines' },
    { value: 'SGP', label: 'Singapore' },
    { value: 'LKA', label: 'Sri Lanka' },
    { value: 'THA', label: 'Thailand' },
    { value: 'VNM', label: 'Vietnam' },
    { value: 'SWE', label: 'Sweden' },
    { value: 'AUS', label: 'Australia' },
    { value: 'NZL', label: 'New Zeland' },
    { value: 'EST', label: 'Estonia' },
    { value: 'DNK', label: 'Denmark' },
    { value: 'FIN', label: 'Finland' },
    { value: 'ISL', label: 'Iceland' },
    { value: 'LTU', label: 'Lithuania' },
    { value: 'LVA', label: 'Latvia' },
    { value: 'NOR', label: 'Norway' },
    { value: 'NOB', label: 'NorwayBokmal' },
    { value: 'SWE', label: 'Sweden' },
    { value: 'GBR', label: 'Great Britain' },
    { value: 'CZE', label: 'Czech Republic' },
    { value: 'SVK', label: 'Slovakia' },
    { value: 'UKR', label: 'Ukrain' },
    { value: 'GBR', label: 'Great Britain' },
    { value: 'SRB', label: 'Serbia' },
    { value: 'TUR', label: 'Turkey' },
    { value: 'HUN', label: 'Hungary' },
    { value: 'AUT', label: 'Austria' },
    { value: 'PRT', label: 'Portugal' },
    { value: 'USA', label: 'United States of America' },
    { value: 'ITA', label: 'Italy' },
];

export const defaultValues = {
    subAuthorisationExternalId: '',
    countryCode: '',
    userSupportInfoHierarchy1ExternalId: '',
    userSupportInfoHierarchy2ExternalId: '',
    userSupportInfoHierarchy3ExternalId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNbr: '',
    supportInfo1: '',
    supportInfo2: '',
    supportInfo3: '',
    lindeManagerCPIDExternal: '',
    businessRelationshipExternalId: '',
    orgUnitExternalId: '',
    orgUnitParentId: 0,
    orgUnitLevel1InternalId: undefined,
    externalPositionExternalId: '',
    locationExternalId: '',
    legalEntityInternalId: 0,
    cornerstoneData1: '',
    cornerstoneData2: '',
    cornerstoneData3: '',
    cornerstoneData4: '',
    locationCode: '',
    supervisorNumber: '',
    positionTitle: '',
    company: '',
    language: '',
    orgUnitLevel1ExternalId: '',
    orgUnitLevel2ExternalId: '',
    orgUnitLevel3ExternalId: '',
    orgUnitLevel4ExternalId: '',
    orgUnitLevel5ExternalId: '',
    userType: '',
};

export const defaultSearchValues = {
    lastName: '',
    firstName: '',
    email: '',
    user_ref: '',
    cornerstoneID: '',
};

export const defaultAddPlantValues = {
    agentName: '',
    plantNo: '',
    comment: '',
    agentGroupName: '',
    timeZone: '',
    contactIds: [],
    companyId: null,
    hpsm: {
        requestType: 'CREATE',
        plantId: 0,
        requestorLastName: '',
        requestorFirstName: '',
        requestorSamAccountName: '',
        agentName: '',
        sapPlantNo: '',
        sapSalesOffice: '',
        sapShippingPoint: '',
        sapRegion: '',
        sapposSalesOffice: '',
        comments: '',
        sapodataGateClient: '',
        plantLIGWERKS: '',
        bocRegionRSP: '',
        sapposSalesOfficeRSP: '',
        zcountry: '',
        erpRoles: '',
        odataGateRoles: '',
        requestedFor: '',
        distributionChannel: '',
    },
};

export const defaultAddContactValues = {
    businessId: 0,
    firstName: '',
    lastName: '',
    email: '',
    contactTypeId: 2,
    changePlants: false,
    language: '',
};

export const defaultAddCompanyValues = {
    companyName: '',
    status: '',
};
