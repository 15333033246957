import { fetchApi } from './fetch-api';
import { createApprovalListApi } from './approvalList';
import { createEntitlementApi } from './entitlement';
import { createUserDetailApi } from './user-detail';
import { createInvitationLogsApi } from './invitation-logs';
import { createAdHocInviteApi } from './adhoc-invites';
import { linxApi } from './linx';

function createApi(api: any) {
    return {
        approvalList: createApprovalListApi(api),
        entitlement: createEntitlementApi(api),
        userDetails: createUserDetailApi(),
        useInvitationLogs: createInvitationLogsApi(api),
        adHocInvite: createAdHocInviteApi(),
        linx: linxApi(api),
    };
}

export const api = createApi(fetchApi);
