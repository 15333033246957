import { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { api } from '../../../services';
import { UserContext } from '../../../context';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import {
    Box,
    CircularProgress,
    Drawer,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    Modal,
    Stack,
    Switch,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import {
    DataGridStyled,
    DrawerBody,
    Error,
    styleContactModal,
    InputContainer,
} from '../../../styled';
import { defaultAddContactValues } from '../../../constants';

var isManager = false;

const Contacts = () => {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const { state, dispatch } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [items, setItems] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>(defaultAddContactValues);
    const [postCountryLoading, setPostCountryLoading] = useState(false);
    const [selectedContact, setSelectedContact] = useState<any>([]);
    const [selectedContactObj, setSelectedContactObj] = useState<any>([]);
    const [inviteLoading, setInviteLoading] = useState<boolean>(false);
    const [languageOptions, setLanguageOptions] = useState<any>([]);
    const [contactPlant, setContactPlant] = useState<any>('');
    const [objectId, setObjectId] = useState<any>('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedContact([]);
        setSelectedContactObj([]);
        setContactPlant('');
        setFormValues({
            ...formValues,
            language: '',
            firstName: '',
            lastName: '',
            email: '',
            contactTypeId: 2,
            changePlants: false,
        });
        isManager = false;
    };

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    useEffect(() => {
        state.chosenLinxCountry &&
            api.linx
                .getSupportedLanguagesLinx(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    if (res && res.length > 0) {
                        setFormValues({
                            ...formValues,
                            language: res.find((language: any) => language.order === 1).code,
                        });
                    } else {
                        setFormValues({
                            ...formValues,
                            language: '',
                        });
                    }
                    if (res && res.length > 0) {
                        setLanguageOptions(
                            res
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((language: any) => {
                                    return {
                                        value: language.code,
                                        label: language.label,
                                    };
                                }),
                        );
                    } else {
                        setLanguageOptions([]);
                    }
                });

        state.chosenLinxCountry &&
            api.linx
                .getLinxContacts(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setIsLoading(false);
                    setItems(res);
                });
    }, [accounts, instance, state.chosenLinxCountry, state.user]);

    useEffect(() => {
        if (state.chosenLinxCountry && open === true) {
            if (languageOptions && languageOptions.length > 0) {
                setFormValues({
                    ...formValues,
                    businessId: state.chosenLinxCountry.value,
                    language: languageOptions[0].value,
                });
            } else {
                setFormValues({
                    ...formValues,
                    businessId: state.chosenLinxCountry.value,
                    language: '',
                });
            }
        }
    }, [state.chosenLinxCountry, open]);

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
        });
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleManagerToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        isManager = event.target.checked;
        setFormValues({
            ...formValues,
            contactTypeId: isManager ? 1 : 2,
        });
    };

    const handleChangePlantToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            changePlants: event.target.checked,
        });
    };

    const handleLanguageChange = (event: any) => {
        setFormValues({
            ...formValues,
            language: event.value,
        });
    };

    const handlePostContact = () => {
        setPostCountryLoading(true);
        let hasError = false;
        if (formValues.firstName.trim().length === 0) {
            let errorName: any = document.getElementById('FirstNameError');
            errorName.style.display = 'inline';
            hasError = true;
        }
        if (formValues.lastName.trim().length === 0) {
            let errorName: any = document.getElementById('LastNameError');
            errorName.style.display = 'inline';
            hasError = true;
        }
        if (formValues.language.trim().length === 0) {
            let languageName: any = document.getElementById('languageError');
            languageName.style.display = 'inline';
            hasError = true;
        }
        if (formValues.email.trim().length === 0) {
            let errorName: any = document.getElementById('EmailError');
            errorName.style.display = 'inline';
            hasError = true;
        } else if (emailRegex.test(formValues.email.trim()) === false) {
            let errorName: any = document.getElementById('EmailInvalid');
            errorName.style.display = 'inline';
            hasError = true;
        }

        if (hasError) {
            return;
        }

        state.chosenLinxCountry &&
            api.linx
                .addLinxContact(state.user, formValues, generateToken(instance, accounts))
                .then((res: any) => {
                    setPostCountryLoading(false);
                    setIsLoading(true);
                    state.chosenLinxCountry &&
                        api.linx
                            .getLinxContacts(
                                state.user,
                                state.chosenLinxCountry.value,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                setIsLoading(false);
                                setItems(res);
                            });
                    !state.chosenLinxCountry &&
                        api.linx
                            .getLinxContacts(state.user, 1, generateToken(instance, accounts))
                            .then((res: any) => {
                                setIsLoading(false);
                                setItems(res);
                            });

                    handleClose();
                });
    };

    useEffect(() => {
        items.map((item: any) => {
            if (item.id === selectedContact[0]) {
                setSelectedContactObj(item);
                if (item.objectId) {
                    setObjectId(item.objectId);
                } else {
                    setObjectId('');
                }
            }
        });
    }, [selectedContact]);

    const handleInviteContact = () => {
        setInviteLoading(true);
        api.linx
            .inviteContact(
                state.user,
                selectedContactObj.id,
                state.appId,
                generateToken(instance, accounts),
            )
            .then((res: any) => {
                api.linx
                    .getLinxContacts(
                        state.user,
                        state.chosenLinxCountry.value,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        setItems(res);
                        setSelectedContactObj(
                            res.find((element: any) => {
                                return element.id === selectedContactObj.id;
                            }),
                        );
                    });
                setInviteLoading(false);
            });
    };

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: t('formField.firstName'),
            width: 200,
        },
        {
            field: 'lastName',
            headerName: t('formField.lastName'),
            width: 150,
        },
        {
            field: 'email',
            headerName: t('formField.email'),
            width: 250,
        },
        {
            field: 'type',
            headerName: t('label.type'),
            width: 150,
        },
        {
            field: 'language',
            headerName: t('label.language'),
            width: 200,
        },
        {
            field: 'status',
            headerName: t('formField.status'),
            width: 200,
        },
        {
            field: 'modify',
            headerName: t('label.modify'),
            renderCell: () => (
                <Button variant="contained" size="small">
                    {t('label.modify')}
                </Button>
            ),
            width: 100,
        },
    ];

    const rows = items;

    useEffect(() => {
        if (selectedContact && selectedContact.length > 0) {
            api.linx
                .getPlantNo(state.user, selectedContact[0], generateToken(instance, accounts))
                .then((res: any) => {
                    if (res) {
                        setContactPlant(res.plantNo);
                    } else {
                        setContactPlant('');
                    }
                });
        }
    }, [selectedContact]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <Button variant="contained" onClick={handleOpen}>
                    {t('label.add')}
                </Button>
            </div>
            <Box sx={{ height: 'calc(100vh - 160px)', width: '100%' }}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <DataGridStyled
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectedContact(newSelectionModel);
                        }}
                        selectionModel={selectedContact}
                        rowsPerPageOptions={[10]}
                    />
                )}
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleContactModal}>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t('formField.firstName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="FirstNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="FirstNameInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-name"
                                    name="firstName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="user-lastname">
                                    {t('formField.lastName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="LastNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="LastNameInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-lastname"
                                    name="lastName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    {t('formField.email')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-email"
                                    name="email"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                            {state.chosenLinxCountryObject &&
                                state.chosenLinxCountryObject.userDelegation && (
                                    <InputContainer>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '0px',
                                            }}
                                        >
                                            <InputLabel
                                                htmlFor="user-manager"
                                                style={{ marginRight: '5px' }}
                                            >
                                                {t('label.manager')}
                                            </InputLabel>
                                            <Switch
                                                checked={isManager}
                                                onChange={handleManagerToggle}
                                            />
                                        </div>
                                    </InputContainer>
                                )}
                            {state.chosenLinxCountryObject &&
                                state.chosenLinxCountryObject.companySupported && (
                                    <InputContainer>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '0px',
                                            }}
                                        >
                                            <InputLabel
                                                htmlFor="user-move"
                                                style={{ marginRight: '5px' }}
                                            >
                                                {t('label.allowToMovePlant')}
                                            </InputLabel>
                                            <Switch
                                                checked={formValues.changePlants}
                                                onChange={handleChangePlantToggle}
                                            />
                                        </div>
                                    </InputContainer>
                                )}

                            <InputContainer>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '0px',
                                    }}
                                >
                                    <InputLabel htmlFor="user-move" style={{ marginRight: '5px' }}>
                                        {t('label.selectLanguage')}
                                    </InputLabel>
                                    <span style={{ color: 'red', marginRight: '15px' }}> *</span>
                                    <Select
                                        defaultValue={formValues.language}
                                        placeholder={formValues.language}
                                        onChange={handleLanguageChange}
                                        options={languageOptions}
                                    />
                                    <Error className="errorMsg" id="languageError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </div>
                            </InputContainer>
                        </Grid>
                    </Grid>
                    <Button variant="contained" onClick={handlePostContact}>
                        {t('label.addContact')}
                    </Button>
                </Box>
            </Modal>

            <Drawer
                anchor="right"
                open={!!selectedContact.length}
                onClose={() => setSelectedContact([])}
                style={{ zIndex: 100000 }}
            >
                {selectedContact && (
                    <DrawerBody>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p style={{ fontWeight: 600, fontSize: '20px' }}>
                                {t('label.contactDetails')}
                            </p>
                            {(selectedContactObj.status === 'Ready' ||
                                selectedContactObj.status === 'Bereit' ||
                                selectedContactObj.status === 'Prêt') &&
                                (!inviteLoading ? (
                                    <Button onClick={handleInviteContact} variant="contained">
                                        {t('label.invite')}
                                    </Button>
                                ) : (
                                    <CircularProgress />
                                ))}
                            {(selectedContactObj.status === 'Requested' ||
                                selectedContactObj.status === 'Angefordert' ||
                                selectedContactObj.status === 'Demandé') &&
                                (!inviteLoading ? (
                                    <Button onClick={handleInviteContact} variant="contained">
                                        {t('label.reInvite')}
                                    </Button>
                                ) : (
                                    <CircularProgress />
                                ))}
                        </div>

                        <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('formField.firstName')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="firstName"
                                        name="firstName"
                                        disabled
                                        value={selectedContactObj.firstName}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('formField.lastName')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="lastName"
                                        name="lastName"
                                        disabled
                                        value={selectedContactObj.lastName}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('formField.email')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="email"
                                        name="email"
                                        disabled
                                        value={selectedContactObj.email}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>
                                        {/* {t("formField.email")} */}
                                        Plant Number
                                    </InputLabel>
                                    <Input
                                        fullWidth
                                        id="plantNo"
                                        name="plantNo"
                                        disabled
                                        value={contactPlant}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.b2cObject')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="b2c"
                                        name="b2c"
                                        disabled
                                        value={objectId}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.language')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="language"
                                        name="language"
                                        disabled
                                        value={selectedContactObj.language}
                                    />
                                </InputContainer>
                            </Grid>

                            {state.chosenLinxCountryObject &&
                                state.chosenLinxCountryObject.companySupported === true && (
                                    <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                        <InputContainer>
                                            <InputLabel>{t('label.changePlant')}</InputLabel>
                                            <Input
                                                fullWidth
                                                id="change"
                                                name="change"
                                                disabled
                                                value={selectedContactObj.changePlants}
                                            />
                                        </InputContainer>
                                    </Grid>
                                )}

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.type')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="type"
                                        name="type"
                                        disabled
                                        value={selectedContactObj.type}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.status')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="status"
                                        name="status"
                                        disabled
                                        value={selectedContactObj.status}
                                    />
                                </InputContainer>
                            </Grid>
                        </Grid>

                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" onClick={() => setSelectedContact([])}>
                                {t('button.close')}
                            </Button>
                        </Stack>
                    </DrawerBody>
                )}
            </Drawer>
        </>
    );
};

export default Contacts;
