export enum AppIdEnum {
    ELEVATE = 1,
    LIDAP = 3,
    LINX = Number(process.env.REACT_APP_LINX_ID),
}

export enum UserAcrEnum {
    PROFILE_EDIT = 'b2c_1a_v1_linde_portal_user_profileedit',
    EMAIL_EDIT = 'b2c_1a_v1_linde_portal_user_change_signinemail',
}
