import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';

interface LocationSearchResult {
    // Define the structure of a location search result if known, otherwise use `any`
    [key: string]: any;
}

interface UseLocationSearchState {
    searchResults: LocationSearchResult[];
    isLocationLoading: boolean;
    searchLocations: (searchValue: string) => void;
}

export const useLocationSearch = (): UseLocationSearchState => {
    const {
        state: { user },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: searchResults = [],
        trigger,
        isLoading: isLocationLoading,
    } = useAsyncTrigger(async (_, searchValue: string) => {
        if (!user) {
            throw new Error('User must be defined');
        }
        const a = await api.adHocInvite.getLocationsElevate(
            user,
            searchValue,
            generateToken(instance, accounts),
        );
        console.log('locationsElevate', a);
        return a;
    });

    const searchLocations = useDebouncedCallback(trigger, 300);

    return { searchResults, isLocationLoading, searchLocations };
};
