import React, { Reducer } from 'react';
import { User } from '../types';
import { BusinessSegmentResponse } from '../types/businessSegment';
import { Nullable } from '../types/util';

interface IContext {
    user: Nullable<User>;
    appId: Nullable<number>;
    appGuid: Nullable<string>;
    entitlementRequestId: Nullable<number>;
    lastAppId: Nullable<number>;
    language: Nullable<string>;
    progress: number;
    progressMessage: string;
    progressActive: boolean;
    orgUnit: any;
    approvalListLoading: boolean;
    linxRegionsAndCountries: any;
    linxRegions: any;
    locationCode: Nullable<string>;
    linxCountries: any;
    chosenLinxCountry: any;
    chosenLinxCountryObject: any;
    linxRoles: string;
    chosenBusinessSegment: Nullable<BusinessSegmentResponse['businessSegments'][number]>;
    businessSegment: Nullable<BusinessSegmentResponse>;
}

function SetUser(user: IContext['user']) {
    return { type: 'SET_USER', data: user } as const;
}

function SetAppId(appId: IContext['appId']) {
    return { type: 'SET_APPID', data: appId } as const;
}

function SetBusinessSegment(segment: IContext['businessSegment']) {
    return { type: 'SET_BUSINESS_SEGMENT', data: segment } as const;
}

function SetLocationCode(code: IContext['locationCode']) {
    return { type: 'SET_LOCATION_CODE', data: code } as const;
}

function SetChosenBusinessSegment(segment: IContext['chosenBusinessSegment']) {
    return { type: 'SET_CHOSEN_BUSINESS_SEGMENT', data: segment } as const;
}

function SetLanguage(language: IContext['language']) {
    return { type: 'SET_LANGUAGE', data: language } as const;
}

function SetProgress(progress: IContext['progress']) {
    return { type: 'SET_PROGRESS', data: progress } as const;
}

function SetProgressLabel(label: IContext['progressMessage']) {
    return { type: 'SET_PROGRESS_LABEL', data: label } as const;
}

function SetProgressActive(active: IContext['progressActive']) {
    return { type: 'SET_PROGRESS_ACTIVE', data: active } as const;
}

function SetSelectedOrgUnit(orgUnit: IContext['orgUnit']) {
    return { type: 'SET_SELECTED_ORGUNIT', data: orgUnit } as const;
}

function SetIsApprovalListLoading(loading: IContext['approvalListLoading']) {
    return { type: 'SET_IS_APPROVALLIST_LOADING', data: loading } as const;
}

function SetLinxRegionsAndCountries(data: IContext['linxRegionsAndCountries']) {
    return { type: 'SET_LINX_REGIONS_AND_COUNTRIES', data: data } as const;
}

function SetLinxRegions(regions: IContext['linxRegions']) {
    return { type: 'SET_LINX_REGIONS', data: regions } as const;
}

function SetLinxCountries(countries: IContext['linxCountries']) {
    return { type: 'SET_LINX_COUNTRIES', data: countries } as const;
}

function SetChosenLinxCountry(country: IContext['chosenLinxCountry']) {
    return { type: 'SET_CHOSEN_LINX_COUNTRY', data: country } as const;
}

function SetChosenLinxCountryObject(countryObject: IContext['chosenLinxCountryObject']) {
    return { type: 'SET_CHOSEN_LINX_COUNTRY_OBJECT', data: countryObject } as const;
}

function SetLinxRoles(roles: IContext['linxRoles']) {
    return { type: 'SET_LINX_ROLES', data: roles } as const;
}

type IContextAction =
    | ReturnType<typeof SetUser>
    | ReturnType<typeof SetAppId>
    | ReturnType<typeof SetBusinessSegment>
    | ReturnType<typeof SetLocationCode>
    | ReturnType<typeof SetChosenBusinessSegment>
    | ReturnType<typeof SetLanguage>
    | ReturnType<typeof SetProgress>
    | ReturnType<typeof SetProgressLabel>
    | ReturnType<typeof SetProgressActive>
    | ReturnType<typeof SetSelectedOrgUnit>
    | ReturnType<typeof SetIsApprovalListLoading>
    | ReturnType<typeof SetLinxRegionsAndCountries>
    | ReturnType<typeof SetLinxRegions>
    | ReturnType<typeof SetLinxCountries>
    | ReturnType<typeof SetChosenLinxCountry>
    | ReturnType<typeof SetChosenLinxCountryObject>
    | ReturnType<typeof SetLinxRoles>;

const InitialState: IContext = {
    user: null,
    appId: Number(localStorage.getItem('appId')) || null,
    appGuid: localStorage.getItem('appGuid') || null,
    entitlementRequestId: Number(localStorage.getItem('entitlementRequestId')) || null,
    lastAppId: null,
    language: String(localStorage.getItem('i18nextLng')) || null,
    progress: 0,
    progressMessage: '',
    progressActive: false,
    orgUnit: null,
    approvalListLoading: false,
    linxRegionsAndCountries: [],
    linxRegions: [],
    linxCountries: [],
    chosenLinxCountry: null,
    chosenLinxCountryObject: null,
    linxRoles: '',
    locationCode: null,
    chosenBusinessSegment: null,
    businessSegment: null,
};

interface UserContext {
    state: IContext;
    dispatch: React.Dispatch<IContextAction>;
}

export const UserContext = React.createContext<UserContext>({
    state: InitialState,
    dispatch: () => {},
});

const UserReducer = (state: IContext, action: IContextAction): IContext => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.data };
        case 'SET_APPID':
            return { ...state, appId: action.data };
        case 'SET_BUSINESS_SEGMENT':
            return { ...state, businessSegment: action.data };
        case 'SET_CHOSEN_BUSINESS_SEGMENT':
            return { ...state, chosenBusinessSegment: action.data };
        case 'SET_LOCATION_CODE':
            return { ...state, locationCode: action.data };
        case 'SET_LANGUAGE':
            return { ...state, language: action.data };
        case 'SET_PROGRESS':
            return { ...state, progress: action.data };
        case 'SET_PROGRESS_LABEL':
            return { ...state, progressMessage: action.data };
        case 'SET_PROGRESS_ACTIVE':
            return { ...state, progressActive: action.data };
        case 'SET_SELECTED_ORGUNIT':
            return { ...state, orgUnit: action.data };
        case 'SET_IS_APPROVALLIST_LOADING':
            return { ...state, approvalListLoading: action.data };
        case 'SET_LINX_REGIONS_AND_COUNTRIES':
            return { ...state, linxRegionsAndCountries: action.data };
        case 'SET_LINX_REGIONS':
            return { ...state, linxRegions: action.data };
        case 'SET_LINX_COUNTRIES':
            return { ...state, linxCountries: action.data };
        case 'SET_CHOSEN_LINX_COUNTRY':
            return { ...state, chosenLinxCountry: action.data };
        case 'SET_CHOSEN_LINX_COUNTRY_OBJECT':
            return { ...state, chosenLinxCountryObject: action.data };
        case 'SET_LINX_ROLES':
            return { ...state, linxRoles: action.data };
        default: {
            console.error(`Unhandled action type`);
            return state;
        }
    }
};

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer<Reducer<IContext, IContextAction>>(
        UserReducer,
        InitialState,
    );
    const value = { state, dispatch };
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
