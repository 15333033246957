import { AppIdEnum } from '../types/app';

export const getLocaleKey = (browserLocale: any) => {
    let language: string;
    if (localStorage.getItem('i18nextLng') !== null) {
        language = localStorage.getItem('i18nextLng')!!.slice(0, 2);
    } else {
        language = browserLocale.slice(0, 2);
    }
    return language;
};

type FormValuesType = Record<string, any>;
type MandatoryFieldsType = Record<string, boolean>;

export const handleRequiredInput = (
    event: any,
    mandatoryFieldsProp: MandatoryFieldsType | undefined,
    formValuesProp: FormValuesType,
    setManagersMandatoryOnSubmit: (value: boolean) => void,
    setLindeManagerRequired: (value: boolean) => void,
    state: any,
) => {
    event.preventDefault();

    if (!mandatoryFieldsProp) return; // Early return if mandatoryFieldsProp is undefined

    const fieldMappings = [
        { field: 'FirstName', formField: 'firstName', errorId: 'FirstNameError' },
        { field: 'LastName', formField: 'lastName', errorId: 'LastNameError' },
        { field: 'Email', formField: 'email', errorId: 'EmailError' },
        { field: 'LocationExternalId', formField: 'locationExternalId', errorId: 'LocationError' },
        {
            field: 'SupervisorNumber',
            formField: 'supervisorNumber',
            errorId: 'SupervisorError',
            condition: state.appId === AppIdEnum.ELEVATE,
        },
        { field: 'PhoneNbr', formField: 'phoneNbr', errorId: 'PhoneError' },
        { field: 'CountryCode', formField: 'countryCode', errorId: 'CountryCodeError' },
        {
            field: 'SubAuthorisationExternalId',
            formField: 'subAuthorisationExternalId',
            errorId: 'SubAuthError',
            checkZero: true,
        },
        {
            field: 'ExternalPositionExternalId',
            formField: 'externalPositionExternalId',
            errorId: 'ExternalPositionError',
        },
        {
            field: 'LegalEntityInternalId',
            formField: 'legalEntityInternalId',
            errorId: 'LegalEntityError',
        },
        {
            field: 'BusinessRelationship',
            formField: 'businessRelationshipExternalId',
            errorId: 'BusinessRelationshipError',
        },
        { field: 'Language', formField: 'language', errorId: 'LanguageError' },
        // Special case for IntelexLocationCode
        {
            field: 'IntelexLocationCode',
            formField: 'locationCode',
            errorId: 'IntelexLocationError',
            alternativeField: 'intelexLocationCode',
        },
    ];

    fieldMappings.forEach(
        ({ field, formField, errorId, condition = true, checkZero = false, alternativeField }) => {
            if (mandatoryFieldsProp[field] && condition) {
                const value = formValuesProp[formField];
                const alternativeValue = alternativeField
                    ? formValuesProp[alternativeField]
                    : undefined;
                const isEmpty =
                    (value === undefined ||
                        value === null ||
                        (typeof value === 'string' && value.trim().length === 0) ||
                        (checkZero && value === 0)) &&
                    (alternativeField
                        ? alternativeValue === undefined ||
                          alternativeValue === null ||
                          (typeof alternativeValue === 'string' &&
                              alternativeValue.trim().length === 0)
                        : true);

                if (isEmpty) {
                    const errorElement = document.getElementById(errorId);
                    if (errorElement) errorElement.style.display = 'inline';
                }
            }
        },
    );

    if (mandatoryFieldsProp.LindeManagerCPID) {
        if (
            !formValuesProp.lindeManagerCPIDExternal ||
            (typeof formValuesProp.lindeManagerCPIDExternal === 'string' &&
                formValuesProp.lindeManagerCPIDExternal.trim().length === 0)
        ) {
            setManagersMandatoryOnSubmit(true);
        }
        if (
            !formValuesProp.lindeManagerCPIDInternal ||
            (typeof formValuesProp.lindeManagerCPIDInternal === 'string' &&
                formValuesProp.lindeManagerCPIDInternal.trim().length === 0)
        ) {
            setLindeManagerRequired(true);
        }
    }
};

export const handleEmptyInputs = (
    mandatoryFieldsProp: MandatoryFieldsType | undefined,
    formValuesProp: FormValuesType,
    page?: string,
) => {
    if (!mandatoryFieldsProp) return false; // Return false if mandatoryFieldsProp is undefined

    const fieldsToCheck = [
        { field: 'LindeManagerCPID', formField: 'lindeManagerCPIDInternal' },
        { field: 'FirstName', formField: 'firstName' },
        { field: 'LastName', formField: 'lastName' },
        { field: 'CountryCode', formField: 'countryCode' },
        { field: 'Email', formField: 'email', skipCheck: page === 'change-data' },
        { field: 'LocationExternalId', formField: 'locationExternalId' },
        { field: 'PhoneNbr', formField: 'phoneNbr' },
        { field: 'SupervisorNumber', formField: 'supervisorNumber' },
        { field: 'BusinessRelationship', formField: 'businessRelationshipExternalId' },
        { field: 'ExternalPositionExternalId', formField: 'externalPositionExternalId' },
        { field: 'Language', formField: 'language' },
        { field: 'LegalEntityInternalId', formField: 'legalEntityInternalId' },
        { field: 'OrgUnitExternalId', formField: 'orgUnitExternalId' },
        { field: 'SubAuthorisationExternalId', formField: 'subAuthorisationExternalId' },
        // Special case for IntelexLocationCode
        {
            field: 'IntelexLocationCode',
            formField: 'locationCode',
            alternativeField: 'intelexLocationCode',
        },
    ];

    return fieldsToCheck.some(({ field, formField, skipCheck = false, alternativeField }) => {
        if (skipCheck) return false;
        const value = formValuesProp[formField];
        const alternativeValue = alternativeField ? formValuesProp[alternativeField] : undefined;
        return (
            mandatoryFieldsProp[field] &&
            (value === undefined ||
                value === null ||
                (typeof value === 'string' && value.trim().length === 0)) &&
            (alternativeField
                ? alternativeValue === undefined ||
                  alternativeValue === null ||
                  (typeof alternativeValue === 'string' && alternativeValue.trim().length === 0)
                : true)
        );
    });
};
