import { User } from '../../types';

export const createInvitationLogsApi = (fetchApi: any) => {
    function getInvitationLogs(
        loggedUser: User,
        appId: number,
        businessSegmentId: number,
        token: any,
    ) {
        if (appId === 1 || appId === 8) {
            return fetchApi(
                `api/EntitlementRequest/GetFileNames/${businessSegmentId}`,
                loggedUser.language,
                'GET',
                token,
            );
        } else if (appId === 3) {
            return fetchApi(
                `api/EntitlementRequest/GetFileNames/1`,
                loggedUser.language,
                'GET',
                token,
            );
        }
    }

    function getInvitationLogErrors(loggedUser: User, Id: number | undefined, token: any) {
        return fetchApi(
            `api/EntitlementRequest/GetInvitationErrorLogs/${Id}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    return {
        getInvitationLogs,
        getInvitationLogErrors,
    };
};
