import { useMsal } from '@azure/msal-react';
import { Buffer } from 'buffer';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { UserAcrEnum } from '../../../types/app';

export const useUpdateUserInfo = () => {
    const {
        state: { user },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();

    const { trigger, isLoading, result } = useAsyncTrigger(async () => {
        if (!user) {
            throw new Error('User must be defined');
        }
        const tokenHashed = window.location.hash.replace('#id_token=', '');

        if (tokenHashed) {
            const base64Payload = tokenHashed.split('.')[1];
            const decodedPayload = Buffer.from(base64Payload, 'base64').toString('utf-8');
            const idTokenClaims = JSON.parse(decodedPayload);

            if (
                idTokenClaims &&
                (idTokenClaims.acr === UserAcrEnum.PROFILE_EDIT ||
                    idTokenClaims.acr === UserAcrEnum.EMAIL_EDIT)
            ) {
                const userDetailInfo2 = await api.adHocInvite.getUserDetailedInfo2(
                    user,
                    generateToken(instance, accounts),
                    {
                        email: idTokenClaims.mail,
                        userObjectId: idTokenClaims.sub,
                        id: null,
                    },
                );
                let userInfoToUpdate = {};
                if (idTokenClaims.acr === UserAcrEnum.EMAIL_EDIT) {
                    userInfoToUpdate = { email: idTokenClaims.mail };
                }
                if (idTokenClaims.acr === UserAcrEnum.PROFILE_EDIT) {
                    userInfoToUpdate = {
                        firstName: idTokenClaims.givenname,
                        lastName: idTokenClaims.surname,
                    };
                }
                const { data: userMasterData } = await api.adHocInvite.updateUserObject(
                    user,
                    { ...userDetailInfo2, ...userInfoToUpdate },
                    generateToken(instance, accounts),
                );

                if (userMasterData) {
                    await api.adHocInvite.updateCornerstoneUserFromEntitlementRequest(
                        user,
                        userMasterData,
                        generateToken(instance, accounts),
                    );
                }
            }
        }
        const a = await api.entitlement.getUserInformation(
            user,
            generateToken(instance, accounts),
            {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                userObjectId: user.id,
                id: null,
            },
        );
        console.log('alooo', a);
        return a;
    });

    useEffect(() => {
        if (user && user.firstName && user.email) {
            trigger();
        }
    }, [trigger, user]);

    return { isLoading, result };
};
