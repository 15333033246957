import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';

interface UseOrgUnitsState {
    orgUnits?: OrgUnit;
    isOrgUnitsLoading: boolean;
    getOrgUnits: () => void;
}

export const useOrgUnits = (): UseOrgUnitsState => {
    const {
        state: { user, chosenBusinessSegment },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: orgUnits,
        trigger: getOrgUnits,
        isLoading: isOrgUnitsLoading,
    } = useAsyncTrigger(async () => {
        if (!user || !chosenBusinessSegment?.internalId) {
            throw new Error('User and business segment must be defined');
        }

        return await api.adHocInvite.getOrgUnits(
            user,
            chosenBusinessSegment.internalId,
            generateToken(instance, accounts),
        );
    });

    return { orgUnits, isOrgUnitsLoading, getOrgUnits };
};
