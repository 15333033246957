import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { SupervisorsResponse } from '../../../types/user';

interface UseSupervisorsSearchState {
    searchResults: SupervisorsSearchList[];
    isSupervisorsLoading: boolean;
    searchSupervisors: (searchValue: string, onComplete: () => void) => void;
}

interface SupervisorsSearchList {
    id: string;
    name: string;
}

function mapResponseToOptions(response: SupervisorsResponse[]): SupervisorsSearchList[] {
    return response.map((supervisor) => ({
        id: supervisor.number,
        name: `${supervisor.fullName} (${supervisor.number})${
            supervisor.email ? ' - ' + supervisor.email : ''
        }`,
    }));
}

export const useSupervisorsSearch = (): UseSupervisorsSearchState => {
    const {
        state: { user },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: searchResults = [],
        trigger,
        isLoading: isSupervisorsLoading,
    } = useAsyncTrigger<
        UseSupervisorsSearchState['searchResults'],
        Parameters<UseSupervisorsSearchState['searchSupervisors']>
    >(async (_, searchValue, onComplete) => {
        if (!user) {
            throw new Error('User must be defined');
        }
        const supervisorsResponses = await api.adHocInvite.getSupervisors(
            user,
            searchValue,
            generateToken(instance, accounts),
        );

        onComplete();
        return mapResponseToOptions(supervisorsResponses);
    });

    const searchSupervisors = useDebouncedCallback(trigger, 300);

    return { searchResults, isSupervisorsLoading, searchSupervisors };
};
