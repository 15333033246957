import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';

interface SupportInfo {
    id: string;
    name: string;
}

interface UseSupportInfoState {
    supportInfo?: SupportInfo[];
    isSupportInfoLoading: boolean;
    getSupportInfo: () => void;
}

export const useSupportInfo = (): UseSupportInfoState => {
    const {
        state: { user, chosenBusinessSegment, appId },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: supportInfo = [],
        trigger: getSupportInfo,
        isLoading: isSupportInfoLoading,
    } = useAsyncTrigger(async () => {
        if (!user || !chosenBusinessSegment?.internalId || !appId) {
            throw new Error('User, business segment, and appId must be defined');
        }

        const supportInfoResponse = await api.adHocInvite.getSupportInfo(
            user,
            chosenBusinessSegment.internalId,
            appId,
            generateToken(instance, accounts),
        );

        return supportInfoResponse
            .filter(({ label }) => label)
            .map((data) => ({
                id: data.parameter,
                name: data.label,
            }));
    });

    return { supportInfo, isSupportInfoLoading, getSupportInfo };
};
