import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import { useAsyncTrigger } from '../../../hooks/useAsyncTrigger';
import { api } from '../../../services';
import { generateToken } from '../../../services/fetch-api/auth/authToken';

interface UserSupportInfoOption {
    id: string;
    name: string;
}

interface UseUserSupportInfoState {
    userSupportInfo: UserSupportInfoOption[];
    isUserSupportInfoLoading: boolean;
    getUserSupportInfo: () => void;
}

export const useUserSupportInfo = (): UseUserSupportInfoState => {
    const {
        state: { user, chosenBusinessSegment, appId },
    } = useContext(UserContext);
    const { instance, accounts } = useMsal();
    const {
        result: userSupportInfo = [],
        trigger: getUserSupportInfo,
        isLoading: isUserSupportInfoLoading,
    } = useAsyncTrigger(async () => {
        if (!user || !chosenBusinessSegment?.internalId || !appId) {
            throw new Error('User, business segment, and appId must be defined');
        }

        const userSupportInfoResponse = await api.adHocInvite.getUserSupportInfo(
            user,
            chosenBusinessSegment.internalId,
            appId,
            generateToken(instance, accounts),
        );

        return userSupportInfoResponse
            .filter(({ label }) => label)
            .map((data) => ({
                id: data.parameter,
                name: data.label,
            }));
    });

    return { userSupportInfo, isUserSupportInfoLoading, getUserSupportInfo };
};
