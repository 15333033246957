import React from 'react';
import { useTranslation } from 'react-i18next';

const AuthError: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            {
                <p
                    style={{
                        color: '#1976d2',
                        fontSize: '18px',
                        fontWeight: '700',
                        textAlign: 'center',
                        marginTop: '70px',
                    }}
                >
                    {t('label.authError')}
                </p>
            }
        </>
    );
};

export default AuthError;
