import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/messages/en.json';
import translationDE from './locales/messages/de.json';
import translationES from './locales/messages/es.json';
import translationFR from './locales/messages/fr.json';
import translationCN from './locales/messages/zh.json';
import translationID from './locales/messages/id.json';
import translationHI from './locales/messages/hi.json';
import translationMS from './locales/messages/ms.json';
import translationTL from './locales/messages/tl.json';
import translationTH from './locales/messages/th.json';
import translationSI from './locales/messages/si.json';
import translationVI from './locales/messages/vi.json';
import translationET from './locales/messages/et.json';
import translationDA from './locales/messages/da.json';
import translationFI from './locales/messages/fi.json';
import translationIS from './locales/messages/is.json';
import translationLT from './locales/messages/lt.json';
import translationLV from './locales/messages/lv.json';
import translationNO from './locales/messages/no.json';
import translationSV from './locales/messages/sv.json';
import translationCS from './locales/messages/cs.json';
import translationSK from './locales/messages/sk.json';
import translationUK from './locales/messages/uk.json';
import translationSR from './locales/messages/sr.json';
import translationIT from './locales/messages/it.json';
import translationPT from './locales/messages/pt.json';
import translationBN from './locales/messages/bn.json';
import translationAR from './locales/messages/ar.json';
import translationBG from './locales/messages/bg.json';
import translationEL from './locales/messages/el.json';
import translationKK from './locales/messages/kk.json';
import translationPL from './locales/messages/pl.json';
import translationRO from './locales/messages/ro.json';
import translationTR from './locales/messages/tr.json';
import translationHU from './locales/messages/hu.json';
import translationNL from './locales/messages/de.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
    es: {
        translation: translationES,
    },
    fr: {
        translation: translationFR,
    },
    pt: {
        translation: translationPT,
    },
    zh: {
        translation: translationCN,
    },
    bn: {
        translation: translationBN,
    },
    id: {
        translation: translationID,
    },
    hi: {
        translation: translationHI,
    },
    ms: {
        translation: translationMS,
    },
    tl: {
        translation: translationTL,
    },
    th: {
        translation: translationTH,
    },
    si: {
        translation: translationSI,
    },
    vi: {
        translation: translationVI,
    },
    et: {
        translation: translationET,
    },
    da: {
        translation: translationDA,
    },
    fi: {
        translation: translationFI,
    },
    is: {
        translation: translationIS,
    },
    lt: {
        translation: translationLT,
    },
    lv: {
        translation: translationLV,
    },
    no: {
        translation: translationNO,
    },
    sv: {
        translation: translationSV,
    },
    cs: {
        translation: translationCS,
    },
    sk: {
        translation: translationSK,
    },
    uk: {
        translation: translationUK,
    },
    sr: {
        translation: translationSR,
    },
    it: {
        translation: translationIT,
    },
    ar: {
        translation: translationAR,
    },
    bg: {
        translation: translationBG,
    },
    el: {
        translation: translationEL,
    },
    kk: {
        translation: translationKK,
    },
    pl: {
        translation: translationPL,
    },
    ro: {
        translation: translationRO,
    },
    tr: {
        translation: translationTR,
    },
    hu: {
        translation: translationHU,
    },
    nl: {
        translation: translationNL,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en-US',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
