import { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { api } from '../../../services';
import { UserContext } from '../../../context';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import {
    Box,
    Autocomplete,
    Checkbox,
    CircularProgress,
    Drawer,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    Modal,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
    DataGridStyled,
    Error,
    DrawerBody,
    styleModal,
    styleContactModal,
    InputContainer,
    stylePlantModal,
} from '../../../styled';
import { defaultAddContactValues, defaultAddPlantValues } from '../../../constants';
var isManager = false;

const Plants = () => {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const { state, dispatch } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedPlant, setSelectedPlant] = useState<any>([]);
    const [selectedPlantObj, setSelectedPlantObj] = useState<any>([]);
    const [items, setItems] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>(defaultAddPlantValues);
    const [contactFormValues, setContactFormValues] = useState<any>(defaultAddContactValues);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isNumberValid, setIsNumberValid] = useState(true);
    const [contactAssign, setContactAssign] = useState(true);
    const [companyAssign, setCompanyAssign] = useState(false);
    const [contacts, setContacts] = useState<any>([]);
    const [openContact, setOpenContact] = useState(false);
    // const [isManager, setIsManager] = useState(false);
    const [addContactLoading, setAddContactLoading] = useState(false);
    const [getContactsLoading, setGetContactsLoading] = useState(false);
    const [addPlantLoading, setAddPlantLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([]);
    const [rowSelectionCompany, setRowSelectionCompany] = useState<GridSelectionModel>([]);
    const [contactRequiredModalOpen, setContactRequiredModalOpen] = useState(false);
    const [companyRequiredModalOpen, setCompanyRequiredModalOpen] = useState(false);
    const [posRegions, setPosRegions] = useState<any>([]);
    const [posRegionsBOC, setPosRegionsBOC] = useState<any>([]);
    const [agentGroup, setAgentGroup] = useState<any>([]);
    const [showDeleted, setShowDeleted] = useState(false);
    const [contactsPerPlant, setContactsPerPlant] = useState<any>([]);
    const [availableContacts, setAvailableContacts] = useState<any>([]);
    const [deassignContact, setDeassignContact] = useState<any>([]);
    const [assignContact, setAssignContact] = useState<any>([]);
    const [assignContactLoading, setAssignContactLoading] = useState<boolean>(false);
    const [deassignContactLoading, setDeassignContactLoading] = useState<boolean>(false);
    const [reassignCompany, setReassignCompany] = useState<any>([]);
    const [reassignCompanyLoading, setReassignCompanyLoading] = useState<boolean>(false);
    const [lockPlantLoading, setlockPlantLoading] = useState<boolean>(false);
    const [unlocPlantLoading, setUnlockPlantLoading] = useState<boolean>(false);
    const [deletePlantLoading, setDeletePlantLoading] = useState<boolean>(false);
    const [deletePlantOpen, setDeletePlantOpen] = useState<boolean>(false);
    const [deletePlantFormOpen, setDeletePlantFormOpen] = useState<boolean>(false);
    const [deleteFormValues, setDeleteFormValues] = useState({
        timeStamp: new Date(),
        comment: '',
    });
    const [languageOptions, setLanguageOptions] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [timeZones, setTimezones] = useState<any>([]);
    const [distChannel, setDistChannel] = useState<any>(null);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setRowSelectionModel([]);
        setFormValues({
            ...formValues,
            agentName: '',
            timeZone: '',
            plantNo: '',
            comment: '',
            contactIds: [],
            companyId: null,
            hpsm: {
                ...formValues.hpsm,
                agentName: '',
                comments: '',
                sapPlantNo: '',
                plantLIGWERKS: '',
                sapSalesOffice: '',
                sapShippingPoint: '',
                sapposSalesOffice: '',
                distributionChannel: '',
            },
        });
    };

    console.log(formValues);

    const handleOpenContact = () => setOpenContact(true);
    const handleCloseContact = () => {
        setOpenContact(false);
        setLanguageOptions([]);
        setContactFormValues({
            ...contactFormValues,
            businessId: 0,
            firstName: '',
            lastName: '',
            email: '',
            contactTypeId: 2,
            changePlants: false,
        });
        isManager = false;
        /*        setContactFormValues(defaultAddContactValues)*/
        // setIsManager(true);
        // state.chosenLinxCountry &&
        //     setContactFormValues({
        //         ...defaultAddContactValues,
        //         countryId: state.chosenLinxCountry.value,
        //     });
    };

    const handleOpenContactRequired = () => setContactRequiredModalOpen(true);
    const handleCloseContactRequired = () => setContactRequiredModalOpen(false);
    const handleCloseCompanyRequired = () => setCompanyRequiredModalOpen(false);

    const handleOpenDeletePlant = () => setDeletePlantOpen(true);
    const handleCloseDeletePlant = () => setDeletePlantOpen(false);

    const handleOpenDeleteFormPlant = () => setDeletePlantFormOpen(true);
    const handleCloseDeleteFormPlant = () => setDeletePlantFormOpen(false);

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const distChannelRegex = /^[A-Z0-9]{2}$/;

    useEffect(() => {
        state.chosenLinxCountry &&
            openContact === true &&
            setContactFormValues({
                ...contactFormValues,
                businessId: state.chosenLinxCountry.value,
            });
    }, [state.chosenLinxCountry, openContact]);

    useEffect(() => {
        setIsLoading(true);
        state.chosenLinxCountry &&
            api.linx
                .getLinxPlants(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setIsLoading(false);
                    setItems(res.reverse());
                });
        !state.chosenLinxCountry &&
            api.linx
                .getLinxPlants(state.user, 1, generateToken(instance, accounts))
                .then((res: any) => {
                    setIsLoading(false);
                    setItems(res.reverse());
                    setContactFormValues({
                        ...defaultAddContactValues,
                        businessId: 1,
                    });
                });

        setGetContactsLoading(true);
        state.chosenLinxCountry &&
            api.linx
                .getInactiveContacts(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setGetContactsLoading(false);
                    setContacts(res);
                });
        state.chosenLinxCountry &&
            api.linx
                .getLinxCompanies(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setCompanies(res);
                });
        !state.chosenLinxCountry &&
            api.linx
                .getInactiveContacts(state.user, 1, generateToken(instance, accounts))
                .then((res: any) => {
                    setGetContactsLoading(false);
                    setContacts(res);
                });

        state.chosenLinxCountry &&
            api.linx
                .getLinxPosRegions(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setPosRegions(
                        res.map((data: any) => {
                            return { value: data.code, label: data.name };
                        }),
                    );
                });

        state.chosenLinxCountry &&
            api.linx
                .getPosRegionPerBusiness(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setPosRegionsBOC(
                        res.map((data: any) => {
                            return { value: data.code, label: data.name };
                        }),
                    );
                });

        state.chosenLinxCountry &&
            api.linx
                .getDistChannelField(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setDistChannel(res);
                });

        state.chosenLinxCountry &&
            api.linx
                .getLinxTimeZones(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setTimezones(
                        res.map((data: any) => {
                            return { value: data.value, label: data.name };
                        }),
                    );
                });

        state.chosenLinxCountry &&
            api.linx
                .getLinxAgentGroup(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    setAgentGroup(
                        res.map((data: any) => {
                            return { value: data.value, label: data.name };
                        }),
                    );
                });

        setFormValues({
            ...formValues,
            requestorFirstName: state.user.firstName,
            requestorLastName: state.user.lastName,
            requestorSamAccountName: state.user.SAMAccountName,
        });

        state.chosenLinxCountry &&
            api.linx
                .getSupportedLanguagesLinx(
                    state.user,
                    state.chosenLinxCountry.value,
                    generateToken(instance, accounts),
                )
                .then((res: any) => {
                    if (res.length > 0) {
                        setContactFormValues({
                            ...contactFormValues,
                            language: res.find((language: any) => language.order === 1).code,
                        });
                    } else {
                        setContactFormValues({
                            ...contactFormValues,
                            language: '',
                        });
                    }
                    if (res.length > 0) {
                        setLanguageOptions(
                            res
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((language: any) => {
                                    return {
                                        value: language.code,
                                        label: language.label,
                                    };
                                }),
                        );
                    } else {
                        setLanguageOptions([]);
                    }
                });
        !state.chosenLinxCountry &&
            api.linx
                .getSupportedLanguagesLinx(state.user, 1, generateToken(instance, accounts))
                .then((res: any) => {
                    setContactFormValues({
                        ...contactFormValues,
                        language: res[0].code,
                        businessId: 1,
                    });
                    setLanguageOptions(
                        res.map((language: any) => {
                            return {
                                value: language.code,
                                label: language.label,
                            };
                        }),
                    );
                });
    }, [state.chosenLinxCountry]);

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
        });
        const { name, value } = event.target;
        if (name === 'distChannel') {
            setFormValues({
                ...formValues,
                hpsm: {
                    ...formValues.hpsm,
                    distributionChannel: value.trim(),
                },
            });
        } else if (name === 'agentName') {
            setFormValues({
                ...formValues,
                [name]: value.trim(),
                hpsm: {
                    ...formValues.hpsm,
                    agentName: value.trim(),
                },
            });

            setIsNameValid(
                /^[a-zA-Z0-9" _.,&()\[\]\-/\\u00C0-\\u00FFà-öø-ÿĀ-ſǍ-ǯ]{1,40}$/.test(value),
            );
        } else if (name === 'plantNo') {
            setFormValues({
                ...formValues,
                [name]: value,
                hpsm: {
                    ...formValues.hpsm,
                    sapPlantNo: value,
                    plantLIGWERKS: value,
                    sapShippingPoint: value,
                    sapposSalesOffice: value,
                    sapSalesOffice: value,
                },
            });
            setIsNumberValid(/^[a-zA-Z0-9]+$/.test(value));
        } else if (name === 'comment') {
            setFormValues({
                ...formValues,
                [name]: value.trim(),
                hpsm: {
                    ...formValues.hpsm,
                    comments: value.trim(),
                },
            });
        }
    };

    const handleContactInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
        });
        const { name, value } = event.target;
        setContactFormValues({
            ...contactFormValues,
            [name]: value,
        });
    };

    // console.log(contactFormValues);

    const handlePostContact = () => {
        let hasError = false;
        if (contactFormValues.firstName.trim().length === 0) {
            let errorName: any = document.getElementById('FirstNameError');
            errorName.style.display = 'inline';
            hasError = true;
        }
        if (contactFormValues.lastName.trim().length === 0) {
            let errorName: any = document.getElementById('LastNameError');
            errorName.style.display = 'inline';
            hasError = true;
        }
        if (contactFormValues.email.trim().length === 0) {
            let errorName: any = document.getElementById('EmailError');
            errorName.style.display = 'inline';
            hasError = true;
        }
        if (emailRegex.test(contactFormValues.email.trim()) === false) {
            let errorName: any = document.getElementById('EmailInvalid');
            errorName.style.display = 'inline';
            hasError = true;
        }
        if (contactFormValues.language.trim().length === 0) {
            let errorLanguage: any = document.getElementById('LanguageError');
            errorLanguage.style.display = 'inline';
            hasError = true;
        }

        if (hasError) {
            return;
        }

        setAddContactLoading(true);
        state.chosenLinxCountry &&
            api.linx
                .addLinxContact(state.user, contactFormValues, generateToken(instance, accounts))
                .then((res: any) => {
                    setAddContactLoading(false);
                    setGetContactsLoading(true);
                    state.chosenLinxCountry &&
                        api.linx
                            .getInactiveContacts(
                                state.user,
                                state.chosenLinxCountry.value,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                setGetContactsLoading(false);
                                setContacts(res);
                                setRowSelectionModel(res[0].id);
                                setFormValues({
                                    ...formValues,
                                    contactIds: [res[0].id],
                                });
                            });
                    setOpenContact(false);
                    // setIsManager(true);
                });
    };

    const handleDeassignContact = () => {
        setDeassignContactLoading(true);
        api.linx
            .deassignContact(state.user, deassignContact[0], generateToken(instance, accounts))
            .then((res: any) => {
                setDeassignContactLoading(false);
                api.linx
                    .getLinxContctsForPlant(
                        state.user,
                        selectedPlantObj.id,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        setContactsPerPlant(res);
                    });
                state.chosenLinxCountry &&
                    api.linx
                        .getAvailableLinxContacts(
                            state.user,
                            state.chosenLinxCountry.value,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            setAvailableContacts(res.reverse());
                        });
            });
    };

    const handleReassignCompany = () => {
        setReassignCompanyLoading(true);
        api.linx
            .reassignCompany(
                state.user,
                selectedPlantObj.id,
                reassignCompany[0],
                generateToken(instance, accounts),
            )
            .then(() => {
                api.linx
                    .getLinxPlants(
                        state.user,
                        state.chosenLinxCountry.value,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        setItems(res.reverse());
                        setSelectedPlantObj(
                            res.find((element: any) => {
                                return element.id === selectedPlantObj.id;
                            }),
                        );
                    });
                setReassignCompanyLoading(false);
            });
    };

    const handleAssignContact = () => {
        setAssignContactLoading(true);
        api.linx
            .assignContact(
                state.user,
                assignContact[0],
                selectedPlantObj.id,
                generateToken(instance, accounts),
            )
            .then((res: any) => {
                setAssignContactLoading(false);
                api.linx
                    .getLinxContctsForPlant(
                        state.user,
                        selectedPlantObj.id,
                        generateToken(instance, accounts),
                    )
                    .then((res: any) => {
                        setContactsPerPlant(res);
                    });
                state.chosenLinxCountry &&
                    api.linx
                        .getAvailableLinxContacts(
                            state.user,
                            state.chosenLinxCountry.value,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            setAvailableContacts(res.reverse());
                        });
            });
    };

    const handleChangePlantToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactFormValues({
            ...contactFormValues,
            changePlants: event.target.checked,
        });
    };

    const handleContactToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactAssign(!contactAssign);
    };

    const handleCompanyToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyAssign(!companyAssign);
        if (!companyAssign === false) {
            setFormValues({ ...formValues, companyId: null });
        }
    };

    const handleManagerToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        isManager = event.target.checked;
        setContactFormValues({
            ...contactFormValues,
            contactTypeId: isManager ? 1 : 2,
        });
    };

    useEffect(() => {
        items.map((item: any) => {
            if (item.id === selectedPlant[0]) {
                setSelectedPlantObj(item);
                api.linx
                    .getLinxContctsForPlant(state.user, item.id, generateToken(instance, accounts))
                    .then((res: any) => {
                        setContactsPerPlant(res);
                    });
                state.chosenLinxCountry &&
                    api.linx
                        .getAvailableLinxContacts(
                            state.user,
                            state.chosenLinxCountry.value,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            setAvailableContacts(res.reverse());
                        });
            }
        });
    }, [selectedPlant]);

    //  PRESELECTING POS REGIONS IF ONLY ONE POS REGION IS AVAILABLE
    useEffect(() => {
        if (posRegions.length >= 1) {
            setFormValues({
                ...formValues,
                // ["countryRegion"]: posRegions[0].value,
                hpsm: {
                    ...formValues.hpsm,
                    requestorFirstName: state.user.firstName,
                    requestorLastName: state.user.lastName,
                    requestorSamAccountName: state.user.SAMAccountName,
                    sapRegion: posRegions[0].value,
                },
            });
        } else {
            setFormValues({
                ...formValues,
                // ["countryRegion"]: "",
                hpsm: {
                    ...formValues.hpsm,
                    requestorFirstName: state.user.firstName,
                    requestorLastName: state.user.lastName,
                    requestorSamAccountName: state.user.SAMAccountName,
                    sapRegion: '',
                },
            });
        }

        state.chosenLinxCountry &&
            setContactFormValues({
                ...contactFormValues,
                businessId: state.chosenLinxCountry.value,
            });
    }, [posRegions, state.chosenLinxCountry]);

    //  PRESELECTING POS REGIONS IF ONLY ONE POS REGION IS AVAILABLE
    useEffect(() => {
        if (posRegionsBOC.length >= 1) {
            setFormValues({
                ...formValues,
                // ["countryRegion"]: posRegions[0].value,
                hpsm: {
                    ...formValues.hpsm,
                    requestorFirstName: state.user.firstName,
                    requestorLastName: state.user.lastName,
                    requestorSamAccountName: state.user.SAMAccountName,
                    bocRegionRSP: posRegionsBOC[0].value,
                },
            });
        } else {
            setFormValues({
                ...formValues,
                // ["countryRegion"]: "",
                hpsm: {
                    ...formValues.hpsm,
                    requestorFirstName: state.user.firstName,
                    requestorLastName: state.user.lastName,
                    requestorSamAccountName: state.user.SAMAccountName,
                    bocRegionRSP: '',
                },
            });
        }

        state.chosenLinxCountry &&
            setContactFormValues({
                ...contactFormValues,
                businessId: state.chosenLinxCountry.value,
            });
    }, [posRegionsBOC, state.chosenLinxCountry]);

    //  PRESELECTING ADMIN GROUP IF ONLY ONE GROUP AVAILABLE
    useEffect(() => {
        if (agentGroup.length > 0) {
            setFormValues({
                ...formValues,
                agentGroupName: agentGroup[0].value,
            });
        }
    }, [agentGroup, state.chosenLinxCountry]);

    //PRESELECTING TIME ZONE IF ONLY ONE GROUP AVAILABLE
    useEffect(() => {
        if (timeZones.length > 0 && open === true) {
            setFormValues({
                ...formValues,
                timeZone: timeZones[0].value,
            });
        }
    }, [timeZones, state.chosenLinxCountry, open]);

    const setSapRegion = (value: any) => {
        setFormValues({
            ...formValues,
            // ["countryRegion"]: value.value,
            hpsm: {
                ...formValues.hpsm,
                sapRegion: value.value,
            },
        });
    };

    const setSapRegionBOC = (value: any) => {
        setFormValues({
            ...formValues,
            // ["countryRegion"]: value.value,
            hpsm: {
                ...formValues.hpsm,
                bocRegionRSP: value.value,
            },
        });
    };

    const setAgentGroups = (value: any) => {
        setFormValues({
            ...formValues,
            agentGroupName: value.value,
        });
    };

    const setTimeZone = (value: any) => {
        setFormValues({
            ...formValues,
            timeZone: value.value,
        });
    };

    const handleRequiredInput = (event: any) => {
        event.preventDefault();
        if (formValues.agentName === '') {
            let plantError: any = document.getElementById('PlantError');
            plantError.style.display = 'inline';
        }
        if (formValues.agentName !== '' && !isNameValid) {
            let plantInvalid: any = document.getElementById('PlantInvalid');
            plantInvalid.style.display = 'inline';
        }
        if (formValues.plantNo === '') {
            let plantNoError: any = document.getElementById('PlantNoError');
            plantNoError.style.display = 'inline';
        }
        if (formValues.plantNo !== '' && !isNumberValid) {
            let plantNoInvalid: any = document.getElementById('PlantNoInvalid');
            plantNoInvalid.style.display = 'inline';
        }
        if (formValues.countryRegion === '' && posRegions.length > 1) {
            let regionError: any = document.getElementById('regionError');
            regionError.style.display = 'inline';
        }
        if (formValues.agentGroupName === '' && agentGroup.length > 1) {
            let agentError: any = document.getElementById('agentError');
            agentError.style.display = 'inline';
        }
        if (distChannel && distChannel.length > 0 && formValues.hpsm.distributionChannel === '') {
            let distChannelError: any = document.getElementById('distChannelError');
            distChannelError.style.display = 'inline';
        }
        if (formValues.hpsm.distributionChannel !== '') {
            if (
                distChannel &&
                distChannel.length > 0 &&
                formValues.hpsm.distributionChannel.length !== 2 &&
                !distChannelRegex.test(formValues.hpsm.distributionChannel)
            ) {
                let distChannelInvalid: any = document.getElementById('distChannelInvalid');
                distChannelInvalid.style.display = 'inline';
            }
        }
    };

    const handleEmptyInputs = () => {
        if (
            formValues.agentName === '' ||
            formValues.plantNo === '' ||
            !isNameValid ||
            !isNumberValid ||
            // (distChannel && distChannel.length > 0 && distChannel[0].name.length > 0 && formValues.hpsm.distributionChannel !== '') ||
            (distChannel &&
                distChannel.length > 0 &&
                (distChannel[0].name == null || distChannel[0].name == '') &&
                formValues.hpsm.distributionChannel.length !== 2)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handlePostPlant = (event: any) => {
        if (contactAssign === true && formValues.contactIds.length === 0) {
            setContactRequiredModalOpen(true);
        } else if (companyAssign === true && formValues.companyId === null) {
            setCompanyRequiredModalOpen(true);
        } else {
            if (handleEmptyInputs() === false) {
                if (
                    isNameValid &&
                    isNumberValid &&
                    formValues.agentName !== '' &&
                    formValues.plantNo !== ''
                    // formValues.countryRegion !== ""
                ) {
                    setAddPlantLoading(true);

                    state.chosenLinxCountry &&
                        api.linx
                            .addLinxPlant(
                                state.user,
                                state.chosenLinxCountry.value,
                                formValues,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                if (res.status !== 200) {
                                    setAddPlantLoading(false);
                                } else {
                                    setAddPlantLoading(false);
                                    setOpen(false);

                                    setFormValues({
                                        ...formValues,
                                        agentName: '',
                                        plantNo: '',
                                        comment: '',
                                        timeZone: '',
                                        contactIds: [],
                                        companyId: null,
                                        hpsm: {
                                            ...formValues.hpsm,
                                            agentName: '',
                                            comments: '',
                                            sapPlantNo: '',
                                            plantLIGWERKS: '',
                                            sapSalesOffice: '',
                                            sapShippingPoint: '',
                                            sapposSalesOffice: '',
                                            distributionChannel: '',
                                        },
                                    });

                                    state.chosenLinxCountry &&
                                        api.linx
                                            .getLinxPlants(
                                                state.user,
                                                state.chosenLinxCountry.value,
                                                generateToken(instance, accounts),
                                            )
                                            .then((res: any) => {
                                                setIsLoading(false);
                                                setItems(res.reverse());
                                            });
                                    !state.chosenLinxCountry &&
                                        api.linx
                                            .getLinxPlants(
                                                state.user,
                                                1,
                                                generateToken(instance, accounts),
                                            )
                                            .then((res: any) => {
                                                setIsLoading(false);
                                                setItems(res.reverse());
                                            });
                                }
                            });

                    !state.chosenLinxCountry &&
                        api.linx
                            .addLinxPlant(
                                state.user,
                                1,
                                formValues,
                                generateToken(instance, accounts),
                            )
                            .then((res: any) => {
                                setAddPlantLoading(false);
                                setOpen(false);
                                setFormValues({
                                    ...formValues,
                                    agentName: '',
                                    plantNo: '',
                                    comment: '',
                                    timeZone: '',
                                    contactIds: [],
                                    companyId: null,
                                    hpsm: {
                                        ...formValues.hpsm,
                                        agentName: '',
                                        comments: '',
                                        sapPlantNo: '',
                                        plantLIGWERKS: '',
                                        sapSalesOffice: '',
                                        sapShippingPoint: '',
                                        sapposSalesOffice: '',
                                    },
                                });
                                !state.chosenLinxCountry &&
                                    api.linx
                                        .getLinxPlants(
                                            state.user,
                                            1,
                                            generateToken(instance, accounts),
                                        )
                                        .then((res: any) => {
                                            setIsLoading(false);
                                            setItems(res.reverse());
                                        });
                            });
                }
            } else {
                handleRequiredInput(event);
            }
        }
    };

    const handleLockPlant = () => {
        setlockPlantLoading(true);
        api.linx
            .lockPlant(state.user, selectedPlantObj.id, generateToken(instance, accounts))
            .then((res: any) => {
                state.chosenLinxCountry &&
                    api.linx
                        .getLinxPlants(
                            state.user,
                            state.chosenLinxCountry.value,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            setItems(res.reverse());
                            setSelectedPlantObj(
                                res.find((element: any) => {
                                    return element.id === selectedPlantObj.id;
                                }),
                            );
                        });
                setlockPlantLoading(false);
            });
    };

    const handleUnlockPlant = () => {
        setlockPlantLoading(true);
        api.linx
            .unlockPlant(state.user, selectedPlantObj.id, generateToken(instance, accounts))
            .then((res: any) => {
                state.chosenLinxCountry &&
                    api.linx
                        .getLinxPlants(
                            state.user,
                            state.chosenLinxCountry.value,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            setItems(res.reverse());
                            setSelectedPlantObj(
                                res.find((element: any) => {
                                    return element.id === selectedPlantObj.id;
                                }),
                            );
                        });
                setlockPlantLoading(false);
            });
    };

    const handleDeletePlant = () => {
        setDeletePlantLoading(true);
        api.linx
            .deletePlant(
                state.user,
                selectedPlantObj.id,
                deleteFormValues.comment,
                deleteFormValues.timeStamp.toISOString(),
                generateToken(instance, accounts),
            )
            .then((res: any) => {
                setDeletePlantLoading(false);
                setDeletePlantOpen(false);
                setDeletePlantFormOpen(false);
                setSelectedPlant([]);
                setIsLoading(true);
                state.chosenLinxCountry &&
                    api.linx
                        .getLinxPlants(
                            state.user,
                            state.chosenLinxCountry.value,
                            generateToken(instance, accounts),
                        )
                        .then((res: any) => {
                            setIsLoading(false);
                            setItems(res.reverse());
                        });
                !state.chosenLinxCountry &&
                    api.linx
                        .getLinxPlants(state.user, 1, generateToken(instance, accounts))
                        .then((res: any) => {
                            setIsLoading(false);
                            setItems(res.reverse());
                        });
            });
    };

    const handleClosePlantDetails = () => {
        setDeassignContact([]);
        setAssignContact([]);
        setSelectedPlant([]);
        setReassignCompany([]);
    };

    const columns: GridColDef[] = [
        {
            field: 'agentName',
            headerName: t('label.plantName'),
            width: 400,
        },
        {
            field: 'plantNo',
            headerName: t('label.plantNumber'),
            width: 150,
        },
        {
            field: 'sapUserId',
            headerName: t('label.sapUserId'),
            width: 200,
        },
        {
            field: 'status',
            headerName: t('label.status'),
            width: 150,
        },
        {
            field: 'modify',
            headerName: t('label.modify'),
            renderCell: () => (
                <Button variant="contained" size="small">
                    {t('label.modify')}
                </Button>
            ),
            width: 100,
        },
    ];

    const handleSelectRow = (id: any) => {
        if (formValues.contactIds.includes(id)) {
            // Deselect the row
            setFormValues({
                ...formValues,
                contactIds: formValues.contactIds.filter((rowId: number) => rowId !== id),
            });
        } else {
            // Select the row
            setFormValues({
                ...formValues,
                contactIds: Array.from(new Set([...formValues.contactIds, id])),
            });
        }
    };

    console.log('SELECTED:', selectedPlantObj);

    const columnsContacts: GridColDef[] = [
        {
            field: 'select',
            headerName: 'Select',
            width: 80,
            renderCell: (params) => (
                <Checkbox
                    color="primary"
                    checked={formValues.contactIds.includes(params.row.id)}
                    onChange={() => handleSelectRow(params.row.id)}
                />
            ),
        },
        {
            field: 'firstName',
            headerName: t('formField.firstName'),
            width: 200,
        },
        {
            field: 'lastName',
            headerName: t('formField.lastName'),
            width: 150,
        },
        {
            field: 'email',
            headerName: t('formField.email'),
            width: 250,
        },
        {
            field: 'type',
            headerName: t('label.type'),
            width: 150,
        },
        {
            field: 'language',
            headerName: t('label.language'),
            width: 200,
        },
        {
            field: 'status',
            headerName: t('formField.status'),
            width: 200,
        },
    ];

    const columnsContactsAssign: GridColDef[] = [
        {
            field: 'firstName',
            headerName: t('formField.firstName'),
            width: 200,
        },
        {
            field: 'lastName',
            headerName: t('formField.lastName'),
            width: 150,
        },
        {
            field: 'email',
            headerName: t('formField.email'),
            width: 250,
        },
        {
            field: 'type',
            headerName: t('label.type'),
            width: 150,
        },
        {
            field: 'language',
            headerName: t('label.language'),
            width: 200,
        },
        {
            field: 'status',
            headerName: t('formField.status'),
            width: 200,
        },
    ];

    const columnsCompanies: GridColDef[] = [
        {
            field: 'companyName',
            headerName: 'Company Name',
            width: 200,
        },
    ];

    const rowsActiveAndDeleted = items;
    const rowsActive =
        items &&
        items.filter((data: any) => {
            if (
                data.status !== 'Deleted' &&
                data.status !== 'Gelöscht' &&
                data.status !== 'Supprimé'
            )
                return data;
        });

    const handleDeletePlantFormChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        const { name, value } = event.target;
        setDeleteFormValues((prevFilter) => ({
            ...prevFilter,
            [name]: name === 'timeStamp' ? new Date(value ? value : new Date()) : value,
        }));
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(e) => {
                                    setShowDeleted(!showDeleted);
                                }}
                            />
                        }
                        label={t('label.showDeletedPlants')}
                    />
                </div>
                <Button variant="contained" onClick={handleOpen}>
                    {t('label.add')}
                </Button>
            </div>
            <Box sx={{ height: 'calc(100vh - 160px)', width: '100%' }}>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <DataGridStyled
                        rows={showDeleted ? rowsActiveAndDeleted : rowsActive}
                        columns={columns}
                        pageSize={50}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectedPlant(newSelectionModel);
                        }}
                        selectionModel={selectedPlant}
                        rowsPerPageOptions={[10]}
                    />
                )}
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'auto' }}
            >
                <Box sx={stylePlantModal}>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel>
                                    {t('label.plantName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="PlantError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="PlantInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="agentName"
                                    name="agentName"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel>
                                    {t('label.plantNumber')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="PlantNoError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="PlantNoInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="plantNo"
                                    name="plantNo"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        {posRegions.length > 1 && (
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputLabel htmlFor="user-manager" style={{ marginRight: '5px' }}>
                                    {t('label.region')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="regionError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    defaultValue={posRegions[0]}
                                    id="combo-box-demo"
                                    options={posRegions}
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value: any) => setSapRegion(value)}
                                />
                            </Grid>
                        )}

                        {posRegionsBOC.length > 1 && (
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputLabel htmlFor="user-manager" style={{ marginRight: '5px' }}>
                                    {t('label.region')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="regionError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    defaultValue={posRegionsBOC[0]}
                                    id="combo-box-demo"
                                    options={posRegionsBOC}
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value: any) => setSapRegionBOC(value)}
                                />
                            </Grid>
                        )}

                        {agentGroup.length > 1 && (
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputLabel htmlFor="user-manager" style={{ marginRight: '5px' }}>
                                    Agent Group
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="agentError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    defaultValue={agentGroup[0]}
                                    id="combo-box-demo"
                                    options={agentGroup}
                                    sx={{ width: '100%', marginBottom: '20px' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value: any) => setAgentGroups(value)}
                                />
                            </Grid>
                        )}

                        {timeZones.length > 1 && (
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputLabel htmlFor="user-manager" style={{ marginRight: '5px' }}>
                                    Time Zone
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="regionError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    defaultValue={timeZones[0]}
                                    id="combo-box-demo"
                                    options={timeZones}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '20px',
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value: any) => setTimeZone(value)}
                                />
                            </Grid>
                        )}

                        {distChannel &&
                            distChannel.length > 0 &&
                            (distChannel[0].name == null || distChannel[0].name == '') && (
                                <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                    <InputContainer>
                                        <InputLabel>
                                            Distribution Channel
                                            <span style={{ color: 'red' }}> *</span>
                                            <Error className="errorMsg" id="distChannelError">
                                                {t('errorMessage.RequiredField')}
                                            </Error>
                                            <Error className="errorMsg" id="distChannelInvalid">
                                                {t('errorMessage.InvalidInput') +
                                                    ' (max 2 characters)'}
                                            </Error>
                                        </InputLabel>
                                        <Input
                                            fullWidth
                                            id="distChannel"
                                            name="distChannel"
                                            onChange={handleInputChange}
                                            inputProps={{ maxLength: 2 }}
                                        />
                                    </InputContainer>
                                </Grid>
                            )}

                        <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel>{t('label.commentForSap')}</InputLabel>
                                <Input
                                    fullWidth
                                    id="comment"
                                    name="comment"
                                    onChange={handleInputChange}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '0px',
                                    marginTop: '20px',
                                }}
                            >
                                <InputLabel htmlFor="user-manager" style={{ marginRight: '5px' }}>
                                    {t('label.addContact')}
                                </InputLabel>
                                <Switch checked={contactAssign} onChange={handleContactToggle} />
                            </div>
                        </Grid>

                        {state.chosenLinxCountryObject &&
                            state.chosenLinxCountryObject.companySupported !== null && (
                                <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '0px',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <InputLabel
                                            htmlFor="user-manager"
                                            style={{ marginRight: '5px' }}
                                        >
                                            Assign to Company
                                        </InputLabel>
                                        <Switch
                                            checked={companyAssign}
                                            onChange={handleCompanyToggle}
                                        />
                                    </div>
                                </Grid>
                            )}
                    </Grid>

                    {contactAssign ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div></div>
                                <Button
                                    sx={{ marginTop: '-40px' }}
                                    variant="contained"
                                    onClick={handleOpenContact}
                                >
                                    {t('label.newContact')}
                                </Button>
                            </div>

                            <Box sx={{ height: '40vh', width: '100%' }}>
                                {getContactsLoading ? (
                                    <LinearProgress />
                                ) : (
                                    <DataGridStyled
                                        onSelectionModelChange={(newSelectionModel: any) => {
                                            setFormValues({
                                                ...formValues,
                                                contactIds: Array.from(
                                                    new Set([
                                                        ...formValues.contactIds,
                                                        ...newSelectionModel,
                                                    ]),
                                                ),
                                            });
                                        }}
                                        rowSelectionModel={rowSelectionModel}
                                        rows={contacts}
                                        columns={columnsContacts}
                                        pageSize={50}
                                        rowsPerPageOptions={[10]}
                                        {...contacts}
                                    />
                                )}
                            </Box>
                        </>
                    ) : null}

                    {companyAssign ? (
                        <>
                            <Box sx={{ height: '40vh', width: '100%' }}>
                                {getContactsLoading ? (
                                    <LinearProgress />
                                ) : (
                                    <DataGridStyled
                                        onSelectionModelChange={(newSelectionCompany: any) => {
                                            setFormValues({
                                                ...formValues,
                                                companyId: newSelectionCompany[0],
                                            });
                                        }}
                                        rowSelectionModel={rowSelectionCompany}
                                        rows={companies}
                                        columns={columnsCompanies}
                                        pageSize={50}
                                        rowsPerPageOptions={[10]}
                                        {...companies}
                                    />
                                )}
                            </Box>
                        </>
                    ) : null}

                    {!addPlantLoading ? (
                        <Button
                            sx={{ marginTop: '20px' }}
                            variant="contained"
                            onClick={handlePostPlant}
                        >
                            {t('label.addPlant')}
                        </Button>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Modal>
            <Modal
                open={openContact}
                onClose={handleCloseContact}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleContactModal}>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t('formField.firstName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="FirstNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="FirstNameInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-name"
                                    name="firstName"
                                    onChange={handleContactInputChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="user-lastname">
                                    {t('formField.lastName')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="LastNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="LastNameInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-lastname"
                                    name="lastName"
                                    onChange={handleContactInputChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel>
                                    {t('formField.email')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-email"
                                    name="email"
                                    onChange={handleContactInputChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel htmlFor="user-move" style={{ marginRight: '5px' }}>
                                    {t('label.selectLanguage')}
                                    <span style={{ color: 'red' }}> *</span>
                                    <Error className="errorMsg" id="LanguageError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={languageOptions}
                                    defaultValue={contactFormValues.language}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        setContactFormValues({
                                            ...contactFormValues,
                                            language: value.value,
                                        })
                                    }
                                />
                            </InputContainer>
                            {state.chosenLinxCountryObject &&
                                state.chosenLinxCountryObject.userDelegation && (
                                    <InputContainer>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '0px',
                                            }}
                                        >
                                            <InputLabel
                                                htmlFor="user-manager"
                                                style={{ marginRight: '5px' }}
                                            >
                                                {t('label.manager')}
                                            </InputLabel>
                                            <Switch
                                                checked={isManager}
                                                onChange={handleManagerToggle}
                                            />
                                        </div>
                                    </InputContainer>
                                )}
                            {state.chosenLinxCountryObject &&
                                state.chosenLinxCountryObject.companySupported && (
                                    <InputContainer>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                margin: '0px',
                                            }}
                                        >
                                            <InputLabel
                                                htmlFor="user-move"
                                                style={{ marginRight: '5px' }}
                                            >
                                                {t('label.allowToMovePlant')}
                                            </InputLabel>
                                            <Switch
                                                checked={formValues.changePlants}
                                                onChange={handleChangePlantToggle}
                                            />
                                        </div>
                                    </InputContainer>
                                )}
                        </Grid>
                    </Grid>
                    {!addContactLoading ? (
                        <Button variant="contained" onClick={handlePostContact}>
                            {t('label.addContact')}
                        </Button>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Modal>

            <Modal
                open={contactRequiredModalOpen}
                onClose={handleCloseContactRequired}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={styleContactModal}>
                        <p>{t('label.mustSelectContact')}</p>
                        <Button variant="contained" onClick={handleCloseContactRequired}>
                            {t('button.close')}
                        </Button>
                    </Box>
                </>
            </Modal>

            <Modal
                open={companyRequiredModalOpen}
                onClose={handleCloseCompanyRequired}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={styleContactModal}>
                        <p>You must select company</p>
                        <Button variant="contained" onClick={handleCloseCompanyRequired}>
                            {t('button.close')}
                        </Button>
                    </Box>
                </>
            </Modal>

            <Modal
                open={deletePlantFormOpen}
                onClose={handleCloseDeletePlant}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={styleContactModal}>
                        <p style={{ fontWeight: 600, fontSize: '20px' }}>Plant</p>
                        <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.plantName')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="agentName"
                                        name="agentName"
                                        disabled
                                        value={selectedPlantObj.agentName}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.plantNumber')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="plantNo"
                                        name="plantNo"
                                        disabled
                                        value={selectedPlantObj.plantNo}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.sapUserId')}</InputLabel>
                                    {selectedPlantObj.hpsmrf ? (
                                        <a
                                            href={`${process.env.REACT_APP_B2C_HPSM_URL}${selectedPlantObj.hpsmrf}${process.env.REACT_APP_B2C_HPSM_URL_SECOND}${selectedPlantObj.hpsmrf}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {selectedPlantObj.sapUserId}
                                        </a>
                                    ) : (
                                        <Input
                                            fullWidth
                                            id="hpsmsd"
                                            name="hpsmsd"
                                            disabled
                                            value={selectedPlantObj.sapUserId}
                                        />
                                    )}
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px', marginTop: '15px' }}>
                                <InputContainer>
                                    <TextField
                                        type="date"
                                        name="timeStamp"
                                        label={'Deletion Date'}
                                        value={
                                            deleteFormValues.timeStamp.toISOString().split('T')[0]
                                        }
                                        onChange={handleDeletePlantFormChange}
                                    />
                                </InputContainer>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>Special comment for SAP security team</InputLabel>
                                    <Input
                                        fullWidth
                                        id="comment"
                                        name="comment"
                                        value={deleteFormValues.comment}
                                        onChange={handleDeletePlantFormChange}
                                    />
                                </InputContainer>
                            </Grid>
                        </Grid>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                marginTop: '50px',
                            }}
                        >
                            <Button variant="contained" onClick={handleOpenDeletePlant}>
                                {t('delete')}
                            </Button>
                            <Button variant="contained" onClick={handleCloseDeleteFormPlant}>
                                {t('cancel')}
                            </Button>
                        </div>
                    </Box>
                </>
            </Modal>

            <Modal
                open={deletePlantOpen}
                onClose={handleCloseDeletePlant}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={styleContactModal}>
                        <p>{t('label.areYouSureDeletePlant')}</p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Button variant="contained" onClick={handleDeletePlant}>
                                {t('yes')}
                            </Button>
                            <Button variant="contained" onClick={handleCloseDeletePlant}>
                                {t('no')}
                            </Button>
                        </div>
                    </Box>
                </>
            </Modal>

            <Drawer
                anchor="right"
                open={!!selectedPlant.length}
                onClose={handleClosePlantDetails}
                style={{ zIndex: 100000 }}
            >
                {selectedPlant && (
                    <DrawerBody>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p style={{ fontWeight: 600, fontSize: '20px' }}>
                                {t('label.modifyPlant')}
                            </p>
                            <div>
                                {!lockPlantLoading ? (
                                    <Button
                                        onClick={
                                            selectedPlantObj.isLocked === true
                                                ? handleUnlockPlant
                                                : handleLockPlant
                                        }
                                        style={{ marginRight: '20px' }}
                                        variant="contained"
                                    >
                                        {selectedPlantObj.isLocked === true
                                            ? t('label.unlockPlant')
                                            : t('label.lockPlant')}
                                    </Button>
                                ) : (
                                    <CircularProgress />
                                )}

                                {(selectedPlantObj.status === 'Active' ||
                                    selectedPlantObj.status === 'Actif' ||
                                    selectedPlantObj.status === 'Aktiv') &&
                                    (!deletePlantLoading ? (
                                        <Button
                                            onClick={handleOpenDeleteFormPlant}
                                            variant="contained"
                                        >
                                            {t('label.deletePlant')}
                                        </Button>
                                    ) : (
                                        <CircularProgress />
                                    ))}
                            </div>
                        </div>

                        <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.plantName')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="agentName"
                                        name="agentName"
                                        disabled
                                        value={selectedPlantObj.agentName}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.plantNumber')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="plantNo"
                                        name="plantNo"
                                        disabled
                                        value={selectedPlantObj.plantNo}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.status')}</InputLabel>
                                    <Input
                                        fullWidth
                                        id="status"
                                        name="status"
                                        disabled
                                        value={selectedPlantObj.status}
                                    />
                                </InputContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 'px' }}>
                                <InputContainer>
                                    <InputLabel>{t('label.sapUserId')}</InputLabel>
                                    {selectedPlantObj.hpsmrf ? (
                                        <a
                                            href={`${process.env.REACT_APP_B2C_HPSM_URL}${selectedPlantObj.hpsmrf}${process.env.REACT_APP_B2C_HPSM_URL_SECOND}${selectedPlantObj.hpsmrf}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {selectedPlantObj.sapUserId}
                                        </a>
                                    ) : (
                                        <Input
                                            fullWidth
                                            id="hpsmsd"
                                            name="hpsmsd"
                                            disabled
                                            value={selectedPlantObj.sapUserId}
                                        />
                                    )}
                                </InputContainer>
                            </Grid>
                        </Grid>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: '35px',
                                marginTop: '45px',
                            }}
                        >
                            {selectedPlantObj.company !== null ? (
                                <p
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '20px',
                                    }}
                                >
                                    {'Assigned Parent Company: ' + ' ' + selectedPlantObj.company}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '20px',
                                    }}
                                >
                                    No parent company assigned
                                </p>
                            )}
                            {reassignCompanyLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    disabled={reassignCompany.length > 0 ? false : true}
                                    variant="contained"
                                    onClick={handleReassignCompany}
                                >
                                    Re-assign Parent Company
                                </Button>
                            )}
                        </div>

                        <Box
                            sx={{
                                height: 'auto',
                                width: 'calc(100vw - 360px)',
                            }}
                        >
                            {isLoading ? (
                                <LinearProgress />
                            ) : (
                                <DataGridStyled
                                    rows={companies}
                                    columns={columnsCompanies}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    autoHeight
                                    onSelectionModelChange={(newSelectionModel: any) => {
                                        setReassignCompany(newSelectionModel);
                                    }}
                                    rowSelectionModel={rowSelectionCompany}
                                    {...companies}
                                />
                            )}
                        </Box>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: '35px',
                                marginTop: '45px',
                            }}
                        >
                            <p style={{ fontWeight: 600, fontSize: '20px' }}>
                                {t('label.assignedContacts')}
                            </p>
                            {deassignContactLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    disabled={deassignContact.length > 0 ? false : true}
                                    variant="contained"
                                    onClick={handleDeassignContact}
                                >
                                    {t('label.deassignContact')}
                                </Button>
                            )}
                        </div>

                        <Box
                            sx={{
                                height: 'auto',
                                width: 'calc(100vw - 360px)',
                            }}
                        >
                            {isLoading ? (
                                <LinearProgress />
                            ) : (
                                <DataGridStyled
                                    rows={contactsPerPlant}
                                    columns={columnsContactsAssign}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    autoHeight
                                    onSelectionModelChange={(newSelectionModel: any) => {
                                        setDeassignContact(newSelectionModel);
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    {...contactsPerPlant}
                                />
                            )}
                        </Box>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                height: '35px',
                                marginTop: '45px',
                            }}
                        >
                            <p style={{ fontWeight: 600, fontSize: '20px' }}>
                                {t('label.availableContacts')}
                            </p>
                            {assignContactLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={handleAssignContact}
                                    disabled={assignContact.length > 0 ? false : true}
                                >
                                    {t('label.assignContact')}
                                </Button>
                            )}
                        </div>

                        <Box
                            sx={{
                                height: 'auto',
                                width: 'calc(100vw - 360px)',
                                marginBottom: '40px',
                            }}
                        >
                            {isLoading ? (
                                <LinearProgress />
                            ) : (
                                <DataGridStyled
                                    rows={availableContacts}
                                    columns={columnsContactsAssign}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    autoHeight
                                    onSelectionModelChange={(newSelectionModel: any) => {
                                        setAssignContact(newSelectionModel);
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    {...availableContacts}
                                />
                            )}
                        </Box>

                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" onClick={() => setSelectedPlant([])}>
                                {t('button.close')}
                            </Button>
                        </Stack>
                    </DrawerBody>
                )}
            </Drawer>
        </>
    );
};

export default Plants;
