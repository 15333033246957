import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Returns the value of a prop or state variable from the previous render.
 *
 * The initial value returned by this hook is `undefined` (since there's no "previous"
 * render in the first render).
 *
 * As seen in the React FAQ:
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export function usePrevious<T>(value: T): T | undefined {
    return useRefToPreviousValueDangerously(value).current;
}

/**
 * Returns a {@link MutableRefObject `MutableRefObject`} containing the value of a prop or state
 * variable from the previous render.
 *
 * The name is long and ugly for a reason! Use this sparingly, and only to optimize side effect
 * handling (i.e. useEffect() callback can access this ref without causing additional state update).
 *
 * Adding the return value of this hook to a dependency array will NOT trigger updates since it is
 * a stable reference!
 *
 * If in doubt, use {@link usePrevious `usePrevious()`} instead.
 */
export function useRefToPreviousValueDangerously<T>(value: T): MutableRefObject<T | undefined> {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
}
