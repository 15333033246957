import React, { useState, useEffect, useContext } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TextField, FormControl, styled } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { generateToken } from '../../../services/fetch-api/auth/authToken';
import { UserContext } from '../../../context';
import { api } from '../../../services';
import { useTranslation } from 'react-i18next';
import { MyDataRow } from '../../../types';
import { darken, lighten } from '@mui/material/styles';

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Error': {
        backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const BusinessEvents: React.FC = () => {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const { state, dispatch } = useContext(UserContext);
    const [rows, setRows] = useState<MyDataRow[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const [filterCriteria, setFilterCriteria] = useState({
        objectCriteria: '',
        timeStampFrom: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        timeStampTo: new Date(),
        actionedBy: '',
        message: '',
    });

    useEffect(() => {
        setLoading(true);
        api.linx
            .getBusinessEvents(
                state.user,
                generateToken(instance, accounts),
                filterCriteria,
                state.chosenLinxCountry.value,
            )
            .then((res: any) => {
                setRows(res.reverse());
                setNumberOfPages(res.totalRecords);
                setLoading(false);
            });
    }, [filterCriteria, state.chosenLinxCountry]);

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.5,
        },
        {
            field: 'objectName',
            headerName: t('label.objectCriteria'),
            flex: 1.7,
        },
        {
            field: 'timeStamp',
            headerName: t('label.timeStamp'),
            flex: 1.2,
            type: 'date',
            valueFormatter: (params) =>
                api.linx.getDateTimeInUserTimezone(
                    params.value,
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                ),
        },
        { field: 'actionedBy', headerName: t('label.actionedBy'), flex: 1.8 },

        {
            field: 'message',
            headerName: t('label.message'),
            flex: 2,
            renderCell: (params: any) => (
                <span
                    className="table-cell-trucate"
                    style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                        padding: '10px',
                        lineBreak: 'anywhere',
                    }}
                >
                    {params.formattedValue}
                </span>
            ),
        },
    ];

    const handleFilterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        const { name, value } = event.target;
        setFilterCriteria((prevFilter) => ({
            ...prevFilter,
            [name]:
                name === 'timeStampFrom'
                    ? new Date(
                          value ? value : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
                      )
                    : name === 'timeStampTo'
                      ? new Date(value ? value : new Date())
                      : value,
            pageNumber: 1, // Reset to the first page when filters change
        }));
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                    width: '350px',
                }}
            >
                <FormControl>
                    <TextField
                        type="date"
                        name="timeStampFrom"
                        label={t('label.dateFrom')}
                        value={filterCriteria.timeStampFrom.toISOString().split('T')[0]}
                        onChange={handleFilterChange}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        type="date"
                        name="timeStampTo"
                        label={t('label.dateTo')}
                        value={filterCriteria.timeStampTo.toISOString().split('T')[0]}
                        onChange={handleFilterChange}
                    />
                </FormControl>
            </div>

            <DataGridStyled
                getRowHeight={() => 'auto'}
                loading={loading}
                rows={rows}
                columns={columns}
                autoHeight
                getRowClassName={(params) => `super-app-theme--${params.row.type}`}
            />
        </>
    );
};

export default BusinessEvents;
