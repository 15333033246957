import { IAdHocInvite, IBusinessRelationship, ILocation, User } from '../../types';
import { toast } from 'react-toastify';
import { CornerstoneDataLabel } from '../../types/cornerstoneDataLabel';
import { LindeManager } from '../../types/lindeManagers';
import { MandatoryField } from '../../types/mandatoryField';
import { SupervisorsResponse, UserDataListResponse, UserDetailedInfo } from '../../types/user';
import { fetchApi } from '../fetch-api';
import axios, { AxiosResponse } from 'axios';
import i18n from '../../i18n';

export const createAdHocInviteApi = () => {
    function createAdHocInvite(loggedUser: User, data: IAdHocInvite, token: any) {
        let userIdLidap = loggedUser.userIdLidap;

        // hack
        if (
            loggedUser.email === 'milan.stefanovic@tron-it.com' ||
            loggedUser.email === 'milan.stefanovic2@linde.com'
        ) {
            userIdLidap = 'uk4111@linde.com';
        }

        return fetchApi(
            `api/EntitlementRequest/AdHocInviteUser/1/3/${userIdLidap}`,
            loggedUser.language,
            'POST',
            token,
            data,
        );
    }

    /*    function createAdHocOnlineInviteNE(loggedUser: User, businessSegmentId: number, appId: number, data: any, token: any) {
            let userObjId = '';

            // hack
            if (loggedUser.email === 'milan.stefanovic@tron-it.com' || loggedUser.email === 'milan.stefanovic2@linde.com') {
                userObjId = 'uk4111@linde.com';
            }
            return fetchApi(`api/EntitlementRequest/AdHocInviteOnlineUser/${businessSegmentId}/${appId}/${userObjId}`, loggedUser.language, 'POST', token, data);
        }*/

    async function createAdHocInviteByType(
        loggedUser: User,
        businessSegmentId: number,
        appId: number,
        data: any,
        token: any,
        type: 'online' | 'offline',
    ): Promise<AxiosResponse<number>> {
        let userIdLidap = loggedUser.userIdLidap;
        // hack
        if (
            loggedUser.email === 'milan.stefanovic@tron-it.com' ||
            loggedUser.email === 'milan.stefanovic2@linde.com'
        ) {
            userIdLidap = 'uk4111@linde.com';
        }

        const invitePathApi =
            type === 'online' ? 'AdHocInviteOnlineUser' : 'AdHocInviteOfflineUser';

        return axios.post(
            `api/EntitlementRequest/${invitePathApi}/${businessSegmentId}/${appId}/${userIdLidap}`,
            data,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            },
        );
    }

    function getLocations(loggedUser: User, code: string, token: any) {
        return fetchApi(`api/Location/filter/${code}`, loggedUser.language, 'GET', token);
    }

    function getCountryCodes(loggedUser: User, token: any): Promise<ILocation[]> {
        return fetchApi(`api/Configuration/GetCountryCodes`, loggedUser.language, 'GET', token);
    }

    function getSupervisors(
        loggedUser: User,
        criteria: string,
        token: any,
    ): Promise<SupervisorsResponse[]> {
        return fetchApi(`api/Manager/search/${criteria}`, loggedUser.language, 'GET', token);
    }

    function getBusinessRelationships(
        loggedUser: User,
        businessSegmentId: number,
        token: any,
    ): Promise<IBusinessRelationship[]> {
        return fetchApi(
            `api/AppHierarchy/GetBusinessRelationships/${businessSegmentId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getCornerstoneLabels(
        loggedUser: User,
        businessSegmentId: number,
        appId: number,
        token: any,
    ): Promise<CornerstoneDataLabel[]> {
        return fetchApi(
            `api/AppConfiguration/GetCSDataLabels?businessSegmentInternalID=${businessSegmentId}&appId=${appId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getOrgUnits(
        loggedUser: User,
        businessSegmentId: number,
        token: any,
    ): Promise<OrgUnit> {
        return fetchApi(
            `api/AppHierarchy/GetOrgUnitHierarchy/${businessSegmentId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLocationsElevate(loggedUser: User, params: string, token: any) {
        return fetchApi(
            `api/AppHierarchy/GetLocations?location=${params}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLocationsIntelex(loggedUser: User, params: string, token: any) {
        return fetchApi(
            `api/Location/SearchIntelexLocation/${params}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getExternalPositions(
        loggedUser: User,
        businessSegmentId: number,
        token: any,
    ): Promise<IBusinessRelationship[]> {
        return fetchApi(
            `api/AppHierarchy/GetExternalPosition/${businessSegmentId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getLegalEntities(loggedUser: User, appId: number, token: any) {
        return fetchApi(
            `api/AppHierarchy/GetLegalEntities/${appId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getSubAuthorizationId(
        loggedUser: User,
        appId: number,
        businessSegmnetExternalId: string,
        token: any,
    ) {
        return fetchApi(
            `api/AppHierarchy/GetSubAuthorizationId/${appId}/${businessSegmnetExternalId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getUserSupportInfo(
        loggedUser: User,
        businessSegmentId: number,
        appId: number,
        token: any,
    ): Promise<CornerstoneDataLabel[]> {
        return fetchApi(
            `api/AppConfiguration/GetUserSupportInfoHierarchy?businessSegmentInternalID=${businessSegmentId}&appId=${appId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getSupportInfo(
        loggedUser: User,
        businessSegmentId: number,
        appId: number,
        token: any,
    ): Promise<CornerstoneDataLabel[]> {
        return fetchApi(
            `api/AppConfiguration/GetSupportInfo?businessSegmentInternalID=${businessSegmentId}&appId=${appId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getMandatoryFields(
        loggedUser: User,
        businessSegmentId: number,
        appId: number,
        token: any,
        isOnline: boolean,
    ): Promise<MandatoryField> {
        return fetchApi(
            `api/AppConfiguration/GetMandatoryFields/${businessSegmentId}/${appId}/${isOnline}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    async function getLindeManagers(
        loggedUser: User,
        token: any,
        body: any,
    ): Promise<AxiosResponse<LindeManager[]>> {
        return axios
            .post(`api/Manager/GetLindeManagers`, body, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res;
            })
            .catch((err) => {
                if (err.response.status) {
                    const message = i18n.t('errorMessage.ErrorEntitlementList');
                    toast.error(message);
                } else {
                    const json = JSON.parse(err.response.data);
                    toast.error(json.Detail);
                }
            });
    }

    async function reinviteExpiredUser(loggedUser: User, entitlementRequestId: number, token: any) {
        return axios
            .post(
                `api/EntitlementRequest/ReinviteExpiredUser?entitlementRequestId=${entitlementRequestId}`,
                null,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: await token,
                        'Accept-Language': loggedUser.language,
                    },
                },
            )
            .then((res: any) => {
                toast.success('OK');
                return res;
            })
            .catch((err) => {
                if (err.response.status) {
                    // const message = i18n.t('errorMessage.ErrorEntitlementList')
                    const message = 'Error while reinviting user';
                    toast.error(message);
                } else {
                    const json = JSON.parse(err.response.data);
                    toast.error(json.Detail);
                }
            });
    }

    function getUserData(
        loggedUser: User,
        name: string,
        businessSegmentId: number,
        token: any,
    ): Promise<UserDataListResponse[]> {
        return fetchApi(
            `api/User/GetUsers/${name}?businessSegmentId=${businessSegmentId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    function getUserDetailedInfo(
        loggedUser: User,
        userObjId: number | undefined,
        token: any,
    ): Promise<UserDetailedInfo> {
        return fetchApi(
            `api/User/GetUserDetailedInfo/${userObjId}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    // function getUserDetailedInfo2(loggedUser: User, userObjId: string | undefined, token: any) {
    //     return fetchApi(
    //         `api/User/GetUserDetailedInfo2/${userObjId}`,
    //         loggedUser.language,
    //         'GET',
    //         token,
    //     );
    // }

    async function getUserDetailedInfo2(loggedUser: User, token: any, data: any) {
        return axios
            .post(`api/User/GetUserDetailedInfo2`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                return res.data;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    function getLocationById(loggedUser: User, id: any, token: any) {
        return fetchApi(`api/Location/GetLocationById/${id}`, loggedUser.language, 'GET', token);
    }

    function getIntelexLocationByCode(loggedUser: User, code: string | undefined, token: any) {
        return fetchApi(
            `api/Location/GetIntelexLocationByCode/${code}`,
            loggedUser.language,
            'GET',
            token,
        );
    }

    async function updateUserObject(loggedUser: User, data: any, token: any) {
        let userIdLidap = loggedUser.userIdLidap;
        // hack
        if (
            loggedUser.email === 'milan.stefanovic@tron-it.com' ||
            loggedUser.email === 'milan.stefanovic2@linde.com'
        ) {
            userIdLidap = 'uk4111@linde.com';
        }

        return axios
            .post(`api/User/UpdateUserMasterData`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: await token,
                    'Accept-Language': loggedUser.language,
                },
            })
            .then((res: any) => {
                if (window.location.href.includes('change-user-data')) {
                    toast.success('OK');
                }
                return res;
            })
            .catch((err) => {
                const json = JSON.parse(err.response.data);
                toast.error(json.Detail);
            });
    }

    async function updateCornerstoneUserFromEntitlementRequest(
        loggedUser: User,
        entitlementRequestId: number,
        token: any,
    ) {
        return axios
            .post(
                `api/EntitlementRequest/UpdateCornerstoneUserFromEntitlementRequest?entitlementRequestId=${entitlementRequestId}`,
                null,
                {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: await token,
                        'Accept-Language': loggedUser.language,
                    },
                },
            )
            .then((res: any) => {
                // toast.success('OK')
                return res;
            })
            .catch((err) => {
                if (err.response.status) {
                    // const message = i18n.t('errorMessage.ErrorEntitlementList')
                    const message = 'Error while updating cornerstone user';
                    toast.error(message);
                } else {
                    const json = JSON.parse(err.response.data);
                    toast.error(json.Detail);
                }
            });
    }

    return {
        createAdHocInvite,
        getLocations,
        getSupervisors,
        getCountryCodes,
        getBusinessRelationships,
        getCornerstoneLabels,
        getOrgUnits,
        getLocationsElevate,
        getLocationsIntelex,
        getExternalPositions,
        getLegalEntities,
        getSubAuthorizationId,
        getUserSupportInfo,
        getSupportInfo,
        getMandatoryFields,
        getLindeManagers,
        createAdHocInviteByType,
        getUserData,
        getUserDetailedInfo,
        getUserDetailedInfo2,
        getLocationById,
        updateUserObject,
        getIntelexLocationByCode,
        reinviteExpiredUser,
        updateCornerstoneUserFromEntitlementRequest,
    };
};
